import React from "react";
import { useQuery } from "@tanstack/react-query";

import { commonMedicalFetch } from "@/api";
import SectionContainer from "./SectionContainer";
import useHospitalStore, { ITEM_STATE } from "../hooks/useHospitalStore";

export default function MedicalSection() {
  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  const { data: medicalData } = useQuery({
    queryKey: ["common", "medical", "allow"],
    queryFn: async () => {
      const response = await commonMedicalFetch.get();

      return response.data.items.filter((item: any) => item.is_open_allow);
    }
  });
  return (
    <SectionContainer>
      <div className="mb-[10px]">
        <label htmlFor="h-address" className="label-required mr-[30px] text-[14px] font-medium text-purple-500">
          진료과목
        </label>
        <select
          className="h-[30px] rounded-md border-2 px-[10px]"
          defaultValue=""
          onChange={(e) => {
            const id = +e.currentTarget.value;
            if (!storeData.medical_category) {
              storeAction.setMedicalCategory([
                {
                  m_c_i_id: id,
                  classify: "MEDICAL",
                  state: ITEM_STATE.INSERT
                }
              ]);
              return;
            }
            const hasItem = storeData.medical_category.find((cate) => cate.m_c_i_id === id);
            if (hasItem) {
              storeAction.setMedicalCategory(
                storeData.medical_category.map((cate) => {
                  if (cate.m_c_i_id === id && cate.state === ITEM_STATE.DELETE) {
                    return {
                      ...cate,
                      state: "STAY"
                    };
                  }
                  return cate;
                })
              );
              return;
            }
            storeAction.setMedicalCategory([
              ...storeData.medical_category,
              {
                m_c_i_id: id,
                classify: "MEDICAL",
                state: ITEM_STATE.INSERT
              }
            ]);
          }}
        >
          <option value="" hidden>
            선택해 주세요
          </option>
          {medicalData &&
            medicalData.map((item: any) => (
              <option key={`common-medical-option-key-${item.medical_id}`} value={item.medical_id}>
                {item.text.find((v: any) => v.local === "ko")?.title}
              </option>
            ))}
        </select>
      </div>
      <div className="flex gap-[10px] rounded-md border-2 p-[10px]">
        {medicalData &&
          storeData.medical_category &&
          storeData.medical_category
            .filter((item) => item.state !== ITEM_STATE.DELETE)
            .map((item: any) => (
              <button
                type="button"
                key={`common-medical-key-${item.m_c_i_id}`}
                className="h-[30px] rounded-md bg-slate-300 px-[15px] after:ml-[10px] after:[content:'X']"
                value={item.m_c_i_id}
                onClick={(e) => {
                  const id = +e.currentTarget.value;

                  if (!storeData.medical_category) return;

                  storeAction.setMedicalCategory(
                    storeData.medical_category.map((cate) => {
                      if (cate.m_c_i_id === id) {
                        return {
                          ...cate,
                          state: ITEM_STATE.DELETE
                        };
                      }
                      return cate;
                    })
                  );
                }}
              >
                {
                  medicalData
                    .find((data: any) => data.medical_id === item.m_c_i_id)
                    .text.find((v: any) => v.local === "ko")?.title
                }
              </button>
            ))}
      </div>
    </SectionContainer>
  );
}
