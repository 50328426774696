const request = async (url: URL | string, init?: RequestInit) => {
  return fetch(url, {
    credentials: "include",
    ...init
  })
    .then((res) => res.json().then((data) => ({ ok: res.ok, status: res.status, url: res.url, data })))
    .then((data) => {
      if (data.ok) return data;
      throw data;
    });
};

const cf = {
  get: async (url: string, searchParams?: string) => {
    const newUrl = new URL(url);

    if (searchParams) newUrl.search = searchParams;

    return request(newUrl, { method: "GET" });
  },
  post: async (url: string, body?: BodyInit, header?: HeadersInit) =>
    request(url, {
      method: "POST",
      body: body,
      headers: header
    }),
  delete: async (url: string) => request(url, { method: "DELETE" }),
  put: async (url: string, body: BodyInit, header?: HeadersInit) =>
    request(url, {
      method: "PUT",
      body: body,
      headers: header
    }),
  patch: async (url: string, body: BodyInit, header?: HeadersInit) =>
    request(url, {
      method: "PATCH",
      body: body,
      headers: header
    })
};

export default cf;
