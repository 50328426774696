import React, { useRef } from "react";
import { Link, LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { IconAngleDown, IconAngleUP, IconClose, IconInfo, IconSortDown, IconSortUp } from "@/components/icons";
import { doctorFetch } from "@/api";

const DoctorListQuery = (id: string) => ({
  queryKey: ["hospital", id, "doctor", "list"],
  queryFn: async () => {
    const response = await doctorFetch.get(`hospital=${id}`);
    return response.data.items;
  }
});

export const DoctorListLoader =
  (queryClient: QueryClient) =>
  ({ params: { hospital_id } }: LoaderFunctionArgs) => {
    return queryClient.ensureQueryData<any>(DoctorListQuery(hospital_id as string));
  };

const TABLE_HEAD = [
  { class: "w-[50px]", text: "순서 변경", visible: false },
  { class: "w-[120px]", text: "대표원장여부", visible: true },
  { class: "w-[120px]", text: "프로필이미지", visible: true },
  { class: "w-[100px]", text: "의사명", visible: true },
  { class: "w-[120px]", text: "의사 면허번호", visible: true },
  { class: "w-[120px]", text: "전문의 자격", visible: true },
  { class: "w-[250px]", text: "진료항목", visible: true },
  { class: "w-[140px]", text: "의사상담가능", visible: true },
  { class: "w-[120px]", text: "진료가능여부", visible: true },
  { class: "w-[120px]", text: "후기수", visible: true, sort: "normal" },
  { class: "w-[120px]", text: "평점(건수)", visible: true, sort: "normal" },
  { class: "w-[120px]", text: "수정", visible: false }
];

export default function DoctorListPage() {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const dialogRef = useRef<HTMLDialogElement>(null);

  const { data, isSuccess } = useQuery<any>({
    ...DoctorListQuery(hospitalId),
    initialData: loaderData
  });

  const postMutation = useMutation({
    mutationFn: async (item: any) => doctorFetch.post(item),
    onSuccess: () => {
      alert("등록 성공");
      queryClient.invalidateQueries({ queryKey: DoctorListQuery(hospitalId).queryKey });
    },
    onError: () => alert("등록 실패")
  });

  const patchMutation = useMutation({
    mutationFn: async ({ type, body }: any) => doctorFetch.patch(type, body),
    onSuccess: () => {
      alert("수정 성공");
      queryClient.invalidateQueries({ queryKey: DoctorListQuery(hospitalId).queryKey });
    },
    onError: () => alert("수정 실패")
  });

  const handleRegisteModalSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);

    postMutation.mutate({
      hospital_id: hospitalId,
      text: [{ local: "ko", title: formData.get("name") }],
      type: formData.get("type"),
      d_license: formData.get("license")
    });
  };

  const handleOpenRegistModal = () => {
    const el = dialogRef.current;
    if (el && !el.open) el.showModal();
  };

  return (
    <div>
      <Nav
        list={[
          { title: "병원관리", link: "/hospital/list" },
          { title: "의사정보", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px]">
          <h2 className="text-[18px] font-semibold">의사정보 목록</h2>
        </div>
        <div className="mb-[15px] flex h-[40px] w-[600px] items-center gap-[8px] rounded-md border-2 border-black bg-orange-400 px-[10px]">
          <IconInfo fill="white" />
          <span>의사 면허증 번호 기준으로 의사정보를 관리할 수 있습니다.</span>
        </div>
        <div className="mb-[10px] flex h-[50px] items-center justify-between rounded-md border-2 bg-gray-100 px-[20px]">
          <h3 className="text-[16px]">예뻐지는 성형외과의원</h3>
          <button
            type="button"
            className="rounded-md bg-green-700 px-[20px] py-[8px] text-white"
            onClick={handleOpenRegistModal}
          >
            의사 등록하기
          </button>
        </div>
        <div>
          <table className="mb-[60px] text-center">
            <caption className="sr-only">의사 리스트</caption>
            <colgroup>
              {TABLE_HEAD.map((head) => (
                <col key={`table-col-group-${head.text}`} className={head.class} />
              ))}
            </colgroup>
            <thead className="border-b-2 border-black text-[14px]">
              <tr className="h-[40px] *:relative *:after:absolute *:after:left-full *:after:top-1/2 *:after:h-1/2 *:after:-translate-x-1/4 *:after:-translate-y-1/2 *:after:border-e-2 [&>*:last-child]:after:hidden">
                {TABLE_HEAD.map((head) => (
                  <th key={`table-head-${head.text}`} className="font-normal">
                    <div
                      className="flex w-full items-center justify-center gap-[10px] data-[visible=false]:hidden"
                      data-visible={head.visible}
                    >
                      {head.text}
                      {head.sort && (
                        <span className="relative">
                          <button type="button">
                            <IconSortUp className="absolute top-1/2 -translate-y-[55%]" />
                          </button>
                          <button type="button">
                            <IconSortDown className="absolute top-1/2 -translate-y-[45%]" />
                          </button>
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                data.map((item: any, index: number, arr: any[]) => (
                  <tr key={`doctor-list-${item.doctor_id}`} className="h-[120px] border-b-2">
                    <td>
                      <div className="flex flex-col items-center gap-[5px]">
                        <button
                          type="button"
                          onClick={(e) => {
                            if (!arr[index - 1]?.doctor_id) {
                              alert("최상단 입니다.");
                              return;
                            }
                            patchMutation.mutate({
                              type: "order",
                              body: {
                                base_doctor_id: item.doctor_id,
                                change_doctor_id: arr[index - 1].doctor_id
                              }
                            });
                          }}
                        >
                          <IconAngleUP />
                        </button>
                        <button
                          type="button"
                          onClick={(e) => {
                            if (!arr[index + 1]?.doctor_id) {
                              alert("최하단 입니다.");
                              return;
                            }
                            patchMutation.mutate({
                              type: "order",
                              body: {
                                base_doctor_id: item.doctor_id,
                                change_doctor_id: arr[index + 1].doctor_id
                              }
                            });
                          }}
                        >
                          <IconAngleDown />
                        </button>
                      </div>
                    </td>
                    <td>{item.is_head_director && "대표원장"}</td>
                    <td>
                      <img
                        src={item.profile_image}
                        alt={item.title}
                        width={100}
                        height={100}
                        className="m-auto bg-blue-300"
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>{item.d_license}</td>
                    <td>{item.medical_title}</td>
                    <td>
                      <div className="mx-auto flex w-[90%] flex-wrap gap-[5px]">
                        {item.medical_category?.map((category: string) => (
                          <span
                            key={`medical-category-${category}`}
                            className="inline-block min-w-[50px] rounded-md bg-blue-700 px-[8px] py-[2px] text-white"
                          >
                            {category}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <label className="custom-check-button mr-[10px]">
                        <input
                          type="checkbox"
                          defaultChecked={item.is_consultation}
                          hidden
                          onChange={(e) => {
                            const checked = e.currentTarget.checked;
                            patchMutation.mutate({
                              type: "consult",
                              body: {
                                doctor_id: item.doctor_id,
                                is_consultation: checked
                              }
                            });
                          }}
                        />
                      </label>
                    </td>
                    <td>
                      {item.state === "TREATMENT" && (
                        <span className="inline-block min-w-[50px] rounded-md bg-green-800 px-[8px] py-[2px] text-white">
                          진료중
                        </span>
                      )}
                      {item.state === "LEAVE" && (
                        <span className="inline-block min-w-[50px] rounded-md bg-gray-400 px-[8px] py-[2px] text-white">
                          휴진
                        </span>
                      )}
                      {item.state === "RETIREMENT" && (
                        <span className="inline-block min-w-[50px] rounded-md bg-gray-400 px-[8px] py-[2px] text-white">
                          퇴직
                        </span>
                      )}
                    </td>
                    <td>{item.review_cnt}</td>
                    <td>{item.rating}</td>
                    <td>
                      <Link to={`/hospital/regist/doctor/detail/${item.doctor_id}`}>
                        <span className="inline-block min-w-[50px] rounded-md bg-red-600 px-[8px] py-[2px] text-white">
                          수정
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <dialog ref={dialogRef} className="rounded-xl">
        <form method="dialog" className="w-[500px]" onSubmit={handleRegisteModalSubmit}>
          <div className="flex h-[50px] items-center justify-between bg-gray-600 px-[20px] text-white">
            <h3 className="text-[18px]">의사 등록하기</h3>
            <button type="reset">
              <IconClose fill="white" />
            </button>
          </div>
          <div className="p-[20px]">
            <div className="mb-[20px] flex flex-col gap-[20px]">
              <p>의사 등록을 위해 이름, 면허번호를 입력해 주세요.</p>
              <label>
                <span className="mb-[8px] inline-block">의사명</span>
                <input
                  type="text"
                  className="h-[40px] w-full rounded-md border px-[10px]"
                  name="name"
                  placeholder="의사명을 입력해주세요."
                  required
                />
              </label>
              <label>
                <span className="mb-[8px] block">의사분류</span>
                <div className="relative h-[40px]">
                  <select
                    className="absolute z-10 h-full w-full appearance-none rounded-md border bg-transparent px-[10px] invalid:text-gray-400"
                    name="type"
                    defaultValue=""
                    required
                  >
                    <option value="" hidden>
                      의사 분류를 선택해주세요
                    </option>
                    <option value="DOCTOR">의사</option>
                    <option value="O_DOCTOR">한의사</option>
                    <option value="DENTIST">치과의사</option>
                  </select>
                  <IconAngleDown fill="#4b5563" className="absolute right-[10px] top-1/2 -translate-y-1/2" />
                </div>
              </label>
              <div>
                <label>
                  <span className="mb-[8px] block">의사면허번호</span>
                  <input
                    type="number"
                    className="h-[40px] w-full rounded-md border px-[10px] [&::-webkit-inner-spin-button]:appearance-none"
                    name="license"
                    placeholder="숫자만 입력 가능합니다."
                    required
                  />
                </label>
                <div className="mb-[5px] mt-[10px] flex items-center gap-[5px]">
                  <IconInfo fill="red" />
                  <p className="text-red-500">의사 면허번호는 등록 이후 수정이 불가하므로 신중하게 입력해 주세요.</p>
                </div>
                <div className="flex items-center gap-[5px]">
                  <IconInfo fill="#a9e086" />
                  <p className="inline align-middle">허위 정보를 입력할 경우 불이익이 있을 수 있습니다.</p>
                </div>
              </div>
            </div>
            <div className="h-[30px] text-end">
              <button type="reset" className="mr-[10px] h-full rounded-3xl bg-stone-400 px-[1.5em] text-white">
                취소
              </button>
              <button className="h-full rounded-3xl bg-red-600 px-[1.5em] text-white">등록하기</button>
            </div>
          </div>
        </form>
      </dialog>
    </div>
  );
}
