import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const tz = {
  korea: "Asia/Seoul",
  japan: "Asia/Tokyo",
  vietnam: "Asia/Ho_Chi_Minh",
  newYork: "America/New_York"
} as const;

dayjs.tz.setDefault(tz.korea);
