import React, { useEffect, useMemo, useState } from "react";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from "react-router-dom";

import { IconClose, IconInfo } from "@/components/icons";
import Nav from "@/components/nav";
import { hospitalDetailFetch, hospitalFetch } from "@/api";

import useHospitalStore, {
  ITEM_STATE,
  ImageItem,
  LocaleKey,
  LocaleValue,
  scheduleAllow,
  ScheduleAllowKey,
  scheduleData,
  ScheduleKey
} from "./hooks/useHospitalStore";
import {
  MedicalSection,
  LanguageSection,
  ServiceSection,
  ScheduleSection,
  SubwaySection,
  SectionContainer,
  AddressSection,
  SimpleAddressSection
} from "./components";
import { JSON_LOCALE } from "./constants";

const hospitalQuery = (hospitalId: string) => ({
  queryKey: ["hospital", "detail", hospitalId],
  queryFn: async () => {
    const response = await hospitalDetailFetch.get(hospitalId as string);
    const data = response.data;

    delete data.hospital_id;
    delete data.result_code;

    return response.data;
  }
});

export const HospitalInfoLoader =
  (queryclient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return queryclient.ensureQueryData(hospitalQuery(params.hospital_id as string));
  };

export default function HospitalInfoPage() {
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  const [selectLoacale, setSelectLocale] = useState<LocaleKey>("ko");

  const isKorea = useMemo(() => selectLoacale === "ko", [selectLoacale]);

  const { data: detailData } = useQuery<any>({
    ...hospitalQuery(hospitalId),
    initialData: loaderData,
    enabled: !!hospitalId
  });

  const detailMutation = useMutation({
    mutationFn: async () => {
      const promise = await new Promise<FormData>((resolve) => {
        const formData = new FormData();

        for (const key in scheduleData) {
          formData.set(key, storeData[key as ScheduleKey] ?? "");
        }

        for (const key in scheduleAllow) {
          formData.set(key, storeData[key as ScheduleKey] ?? "");
        }

        if (storeData.shared_category) {
          storeData.shared_category.forEach((cate, index) => {
            formData.set(`shared_category[${index}].shared_category_id`, `${cate.shared_category_id}`);
            formData.set(`shared_category[${index}].classify`, cate.classify);
            formData.set(`shared_category[${index}].state`, cate.state ?? "STAY");
          });
        }
        if (storeData.medical_category) {
          storeData.medical_category.forEach((cate, index) => {
            formData.set(`medical_category[${index}].m_c_i_id`, `${cate.m_c_i_id}`);
            formData.set(`medical_category[${index}].classify`, cate.classify);
            formData.set(`medical_category[${index}].state`, cate.state ?? "STAY");
          });
        }

        JSON_LOCALE.forEach(({ key: LocaleKey }) => {
          if (storeData[LocaleKey] === null) return;

          for (const key in storeData[LocaleKey]) {
            if (key === "main_multi_image") {
              const imageList = storeData[LocaleKey][key];
              imageList?.forEach((item, index) => {
                for (const imgKey in item) {
                  const value = item[imgKey as keyof ImageItem] ?? "";
                  formData.set(
                    LocaleKey.concat(".", key, `[${index}]`, ".", imgKey),
                    typeof value === "number" ? `${value}` : value
                  );
                  if (item.state === undefined) {
                    formData.set(LocaleKey.concat(".", key, `[${index}]`, ".", "state"), "STAY");
                  }
                  if (item.state === "UPDATE" || item.state === "INSERT") {
                    formData.delete(LocaleKey.concat(".", key, `[${index}]`, ".", "image_url"));
                  }
                }
              });
              continue;
            }
            if (key === "thumb_image" || key === "des_image") {
              for (const imgKey in storeData[LocaleKey][key]) {
                const value = storeData[LocaleKey][key][imgKey as keyof ImageItem] ?? "";
                formData.set(LocaleKey.concat(".", key, ".", imgKey), typeof value === "number" ? `${value}` : value);
                if (storeData[LocaleKey][key].state === undefined) {
                  formData.set(LocaleKey.concat(".", key, ".", "state"), "STAY");
                }
                if (storeData[LocaleKey][key].state === "UPDATE" || storeData[LocaleKey][key].state === "INSERT") {
                  formData.delete(LocaleKey.concat(".", key, ".", "image_url"));
                }
              }
              continue;
            }

            formData.set(
              LocaleKey.concat(".", key),
              storeData[LocaleKey]?.[
                key as keyof Omit<LocaleValue, "thumb_image" | "main_multi_image" | "des_image">
              ] ?? ""
            );
          }
        });

        resolve(formData);
      });

      await hospitalDetailFetch.put(hospitalId, promise);
    },
    onSuccess: () => {
      alert("SUCCESS");
      navigate("/hospital/list");
    },
    onError: () => {
      alert("ERROR");
    }
  });

  const patchMutation = useMutation({
    mutationFn: async ({ id, state }: { id: string; state: "WAIT" | "COMPLETE" }) =>
      hospitalFetch.patch("state", id, { state }),
    onSuccess: () => {
      alert("변경에 성공했습니다.");
      navigate("/hospital/list");
    },
    onError: () => {
      alert("실패");
    }
  });

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    const type = e.currentTarget.type;

    if (type === "time") {
      const key = e.currentTarget.name as ScheduleKey;
      if (!value) {
        storeAction.setSchedule(key, "");
        return;
      }

      const formatting = value.replace(":", "");
      storeAction.setSchedule(key, formatting);
      return;
    }

    if (type === "checkbox") {
      const key = e.currentTarget.name as ScheduleAllowKey;
      const checked = (e.currentTarget as HTMLInputElement).checked;

      storeAction.setScheduleAllow(key, checked);
    }

    const key = e.currentTarget.name;
    storeAction.setData(selectLoacale, { [key]: value });
  };

  const readFileAsync = (file: File, id?: number) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = (e) => {
        const dataUrl = e.target?.result?.toString() ?? "";

        if (id) {
          resolve({
            image_id: id,
            image_url: dataUrl,
            image: file,
            state: "UPDATE"
          });
        } else {
          resolve({
            image_url: dataUrl,
            image: file,
            state: "INSERT"
          });
        }
      };

      fileReader.onerror = reject;
    });
  /**
   * 차후 정리 필요
   */
  const handleImageChoose = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name as "thumb_image" | "main_multi_image" | "des_image";

    const files = e.currentTarget.files;

    if (!files) return;

    const promise = [];

    for (let i = 0; i < files.length; i++) {
      if (name === "main_multi_image") {
        promise.push(readFileAsync(files[i]));
      } else {
        promise.push(readFileAsync(files[i], storeData[selectLoacale]?.[name]?.image_id));
      }
    }

    const imageData = (await Promise.all(promise)) as ImageItem[];

    if (name === "main_multi_image") {
      if (storeData[selectLoacale]?.[name]) {
        storeAction.setData(selectLoacale, { [name]: [...storeData[selectLoacale][name], ...imageData] });
        return;
      }
      storeAction.setData(selectLoacale, { [name]: [...imageData] });
      return;
    }

    storeAction.setData(selectLoacale, { [name]: imageData[0] });
  };
  /**
   * 차후 정리 필요
   */
  const handleImageDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.name as "thumb_image" | "main_multi_image" | "des_image";

    const imageId = e.currentTarget.dataset.id;
    const imageUrl = e.currentTarget.dataset.url;

    if (name === "main_multi_image") {
      const imageList = storeData[selectLoacale]?.[name];

      if (!imageList) return;

      if (imageId) {
        const result = imageList.map((item) => {
          if (`${item.image_id}` === imageId) return { ...item, state: ITEM_STATE.DELETE };
          if (item.state === "DELETE") return item;
          return { ...item, state: ITEM_STATE.STAY };
        });
        storeAction.setData(selectLoacale, { [name]: result });
        return;
      }

      const result = imageList.filter((item) => item.image_url !== imageUrl);
      storeAction.setData(selectLoacale, { [name]: result });
      return;
    }

    if (imageId) {
      const imageItem = storeData[selectLoacale]?.[name];
      storeAction.setData(selectLoacale, { [name]: { ...imageItem, state: ITEM_STATE.DELETE } });
      return;
    }

    storeAction.setData(selectLoacale, { [name]: null });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    detailMutation.mutate();
  };

  useEffect(() => {
    if (detailData) storeAction.init(detailData);
    return () => {
      storeAction.reset();
    };
  }, [detailData, storeAction]);

  return (
    <div>
      <Nav
        list={[
          { title: "병원관리", link: "/hospital/list" },
          { title: "등록", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="mr-[15px] inline text-[18px] font-semibold">병원 기본 정보등록</h2>
          <p className="inline">병원의 기본정보를 등록하는 페이지 입니다.</p>
        </div>
        <section>
          <h3 className="mb-[10px] text-[14px] font-medium">병원 기본정보</h3>
          <div>
            {/* form nav */}
            <div className="flex h-[40px]">
              {JSON_LOCALE.map((locale) => (
                <button
                  type="button"
                  key={`hospital-info-${locale.key}`}
                  className="relative mt-[2px] h-full w-[100px] rounded-tl-md rounded-tr-md border-2 bg-slate-300 data-[active=true]:border-b-0 data-[active=true]:bg-white"
                  data-active={locale.key === selectLoacale}
                  onClick={() => setSelectLocale(locale.key)}
                >
                  {locale.text}
                </button>
              ))}
            </div>
            {/* form */}
            <form className="w-[800px] overflow-hidden rounded-lg rounded-tl-none border-2" onSubmit={handleSubmit}>
              {/* deps-1 인사말 */}
              <SectionContainer>
                <div className="mb-[30px]">
                  <label className="label-required text-[14px] font-medium text-purple-500">병원명</label>
                  <input
                    type="text"
                    className="my-[5px] block h-[40px] w-full rounded-md border-2 px-[20px] placeholder:text-black disabled:bg-slate-300"
                    name="title"
                    value={storeData[selectLoacale]?.title ?? ""}
                    placeholder="예뻐지는 성형외과의원"
                    disabled={selectLoacale === "ko"}
                    onChange={handleChangeData}
                  />
                  <div className="flex gap-[10px]">
                    <IconInfo />
                    <span>병원명 수정을 원하시면 관리자에게 문의하기를 통해 문의해주세요</span>
                  </div>
                </div>
                <div className="mb-[30px]">
                  <label className="label-required text-[14px] font-medium text-purple-500">병원슬로건</label>
                  <div className="relative">
                    <input
                      type="text"
                      className="my-[5px] block h-[40px] w-full rounded-md border-2 px-[20px]"
                      name="sub_title"
                      value={storeData[selectLoacale]?.sub_title ?? ""}
                      maxLength={50}
                      required
                      placeholder="한줄로 표현하는 홍보글 작성해주세요."
                      onChange={handleChangeData}
                    />
                    <span className="absolute bottom-[5px] right-[10px]">{`${storeData[selectLoacale]?.sub_title?.length ?? 0} / 50`}</span>
                  </div>
                  <div className="flex gap-[10px]">
                    <IconInfo />
                    <span>병원명 수정을 원하시면 관리자에게 문의하기를 통해 문의해주세요</span>
                  </div>
                </div>
                <div>
                  <label className="label-required text-[14px] font-medium text-purple-500">간단소개</label>
                  <div className="relative my-[5px]">
                    <textarea
                      className="block h-[150px] w-full resize-none rounded-md border-2 p-[20px] placeholder:text-black"
                      name="description"
                      value={storeData[selectLoacale]?.description ?? ""}
                      maxLength={300}
                      required
                      placeholder="실리프팅에 진심, 실리프팅 특화 의료기관 예뻐지는 성형외과 의원입니다."
                      onChange={handleChangeData}
                    />
                    <span className="absolute bottom-[5px] right-[10px]">{`${storeData[selectLoacale]?.description?.length ?? 0} / 300`}</span>
                  </div>
                  <div className="flex items-center gap-[10px]">
                    <IconInfo />
                    <span>
                      병원대표 전화번호, SNS ID, 홈페이지 주소는 등록 할 수 없습니다. 임의로 내용 수정 될 수 있습니다.
                    </span>
                  </div>
                </div>
              </SectionContainer>
              {/* deps-2 주소 */}
              <SectionContainer>
                {isKorea ? <AddressSection /> : <SimpleAddressSection locale={selectLoacale} />}
                {isKorea && <SubwaySection />}
              </SectionContainer>
              {/* deps-3 시간 only Korea */}
              {isKorea && <ScheduleSection />}
              {/* deps-4 진료 only Korea */}
              {isKorea && <MedicalSection />}
              {/* deps-5 언어 only Korea  */}
              {isKorea && <LanguageSection />}
              {/* deps-6 부가서비스 only Korea */}
              {isKorea && <ServiceSection />}
              {/* deps-7 이미지-썸네일 */}
              <SectionContainer>
                <div className="mb-[10px]">
                  <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                    병원 목록 썸네일
                  </h4>
                  <label className="relative inline cursor-pointer rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                    이미지 선택
                    <input
                      type="file"
                      className="sr-only bottom-0 left-0 scroll-mt-[50px]"
                      name="thumb_image"
                      accept="image/*"
                      required={!storeData[selectLoacale]?.thumb_image?.image_url}
                      onChange={handleImageChoose}
                    />
                  </label>
                </div>
                <div>
                  {storeData[selectLoacale]?.thumb_image?.image_url && (
                    <div className="relative mb-1 w-[300px] border-2 bg-green-200">
                      <img
                        src={storeData[selectLoacale]?.thumb_image?.image_url}
                        alt=""
                        className="aspect-[1080/284] rounded-md object-contain"
                      />
                      <button
                        type="button"
                        className="absolute right-2 top-1"
                        name="thumb_image"
                        onClick={handleImageDelete}
                      >
                        <IconClose width={12} heigth={12} />
                      </button>
                    </div>
                  )}
                  <div className="flex gap-[10px]">
                    <IconInfo fill="green" />
                    <span>1080px * 284px, 최대 2M</span>
                  </div>
                </div>
              </SectionContainer>
              {/* deps-7 이미지-대표 */}
              <SectionContainer>
                <div className="mb-[30px]">
                  <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                    병원대표 이미지
                  </h4>
                  <label className="relative inline cursor-pointer rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                    이미지 선택
                    <input
                      type="file"
                      className="sr-only bottom-0 left-0 scroll-mt-[50px]"
                      name="main_multi_image"
                      multiple
                      accept="image/*"
                      required={!storeData[selectLoacale]?.main_multi_image}
                      onChange={handleImageChoose}
                    />
                  </label>
                </div>
                <div>
                  <div className="mb-2 flex flex-wrap gap-2">
                    {storeData[selectLoacale]?.main_multi_image &&
                      storeData[selectLoacale]?.main_multi_image
                        .filter((item) => item.state !== ITEM_STATE.DELETE)
                        .map((item, index) => (
                          <div key={`multi-img-${item.image_id ?? index}`} className="relative w-[300px] bg-green-200">
                            <img src={item.image_url} alt="" className="aspect-[1080/468] rounded-md object-contain" />
                            <button
                              type="button"
                              className="absolute right-2 top-1"
                              name="main_multi_image"
                              data-id={item.image_id}
                              data-url={item.image_url}
                              onClick={handleImageDelete}
                            >
                              <IconClose width={12} heigth={12} />
                            </button>
                          </div>
                        ))}
                  </div>
                  <div className="flex gap-[10px]">
                    <IconInfo fill="green" />
                    <span>1080px * 468px, 개당 최대 2M 씩, 한개 이상등록 가능</span>
                  </div>
                </div>
              </SectionContainer>
              {/* deps-7 이미지-소개 */}
              <SectionContainer>
                <div className="mb-[30px]">
                  <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">병원상세 소개 이미지</h4>
                  <label className="inline cursor-pointer rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                    이미지 선택
                    <input
                      type="file"
                      className="sr-only"
                      name="des_image"
                      accept="image/*"
                      onChange={handleImageChoose}
                    />
                  </label>
                </div>
                <div>
                  {storeData[selectLoacale]?.des_image?.image_url && (
                    <div className="relative mb-2 h-[800px] w-[768px] overflow-y-scroll bg-green-200">
                      <img
                        src={storeData[selectLoacale]?.des_image?.image_url}
                        alt=""
                        className="rounded-md object-contain"
                      />
                      <button
                        type="button"
                        className="absolute right-[10px] top-[10px]"
                        name="des_image"
                        onClick={handleImageDelete}
                      >
                        <IconClose width={12} heigth={12} />
                      </button>
                    </div>
                  )}
                  <div className="flex gap-[10px]">
                    <IconInfo fill="green" />
                    <span>이미지 크기는 W = 1080px, 최대 5M</span>
                  </div>
                </div>
              </SectionContainer>
              {/* deps-last 버튼 */}
              <SectionContainer>
                <div className="flex justify-between">
                  <div className="flex gap-[10px]">
                    {storeData.state !== "COMPLETE" ? (
                      <button
                        type="button"
                        className="rounded-3xl bg-blue-400 px-[20px] py-[10px] text-center text-white"
                        onClick={() => patchMutation.mutate({ id: hospitalId, state: "COMPLETE" })}
                      >
                        승인
                      </button>
                    ) : (
                      <div className="content-center bg-blue-400 px-[20px] py-[10px] text-center text-white">
                        승인완료
                      </div>
                    )}
                  </div>
                  <div className="flex gap-[10px]">
                    <button className="rounded-3xl bg-red-400 px-[20px] py-[10px] text-white">검수 승인신청</button>
                  </div>
                </div>
              </SectionContainer>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}
