import { redirect } from "react-router-dom";
import { session, SESSION_LOGIN_KEY } from "@/libs/utils/storage";

export const loginLoader = async () => {
  const isLogin = SESSION_LOGIN_KEY.every(session.get);

  if (!isLogin) return "";

  alert("이미 로그인이 되어있습니다. 로그아웃 후 접근해주세요.");

  return redirect("/");
};
