import React from "react";

import useHospitalStore from "../hooks/useHospitalStore";

export default function AddressSection() {
  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  return (
    <div>
      <label className="label-required mr-[30px] text-[14px] font-medium text-purple-500">주소</label>
      <button type="button" className="inline-block h-[30px] rounded-md bg-purple-500 px-[20px] text-white">
        주소검색
      </button>
      <div className="my-[10px] mt-[10px] flex gap-[10px]">
        <input
          type="text"
          name="address-number"
          className="h-[40px] w-[100px] rounded-md border-2 bg-slate-300 text-center"
          value={storeData["ko"]?.zipcode ?? ""}
          disabled
        />
        <input
          type="text"
          name="address-search"
          className="h-[40px] grow rounded-md border-2 bg-slate-300 px-[20px]"
          value={storeData["ko"]?.address ?? ""}
          disabled
        />
      </div>
      <input
        type="text"
        name="address-etc"
        className="h-[40px] w-full rounded-md border-2 px-[20px]"
        value={storeData["ko"]?.sub_address ?? ""}
        onChange={(e) => storeAction.setData("ko", { sub_address: e.target.value })}
      />
    </div>
  );
}
