import React from "react";

import Nav from "@/components/nav";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { commonCountryFetch } from "@/api";
import { useLoaderData } from "react-router-dom";

const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

export default function CommonCountryPage() {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();

  const { data: countryList } = useQuery({
    queryKey: ["common", "country"],
    queryFn: async () => {
      const response = await commonCountryFetch.get();
      const items = (response.data.items as {}[])
        .sort((a: any, b: any) => (a.en_name < b.en_name ? -1 : 0))
        .map((item: any) => ({ name: item.en_name, id: item.country_id, type: "item", isOpen: item.is_open_allow }));

      alphabet.forEach((char) => {
        const index = items.findIndex((item) => item.name.startsWith(char));
        items.splice(index, 0, { name: char, id: char, type: "title", isOpen: "Y" });
      });
      return items;
    },
    initialData: loaderData
  });

  const mutation = useMutation({
    mutationFn: async (item: any) =>
      commonCountryFetch.patch({
        update_items: [{ country_id: item.id, allow: item.change }]
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["common", "country"] });
      alert("Success");
    },
    onError: () => {
      alert("Error");
    }
  });

  return (
    <div>
      <Nav
        list={[
          { title: "공통관리", link: null },
          { title: "국가 관리", link: null }
        ]}
      />
      <div className="h-full w-fit bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h1 className="mr-[15px] inline text-[18px] font-semibold">국가 관리</h1>
        </div>
        <div className="columns-[5_250px] gap-0 rounded-lg border-2 [column-rule:solid_2px_rgb(229,231,235)]">
          {(countryList as any).map((item: any) => (
            <div
              key={"country-list-" + item.id + "-" + item.name}
              className="flex items-center gap-[5px] py-[6px] pl-[16px]"
            >
              <input
                type="checkbox"
                id={item.name}
                className={item.type === "item" ? "" : "invisible"}
                name={item.name}
                value={item.id}
                defaultChecked={item.isOpen === "Y"}
                disabled={item.type === "title"}
                onChange={(e) => mutation.mutate({ ...item, change: e.currentTarget.checked ? 0 : 1 })}
              />
              <label htmlFor={item.name} className="h-[30px] leading-[30px]">
                {item.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
