import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Nav from "@/components/nav";
import { IconClose } from "@/components/icons";

export default function EventApprovalList() {
  const dialogRef = useRef<HTMLDialogElement>(null);

  return (
    <div>
      <Nav
        list={[
          { title: "이벤트관리", link: "/event/list" },
          { title: "승인요청목록", link: null }
        ]}
      />
      <div className="w-max bg-white px-[30px] py-[40px] text-black">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="text-[18px] font-semibold">승인 요청 목록</h2>
        </div>
        <table className="mb-[60px] text-center">
          <caption className="sr-only">이벤트 승인 요청 목록 테이블</caption>
          <colgroup>
            <col className="w-[120px]" data-label="요청일시" />
            <col className="w-[120px]" data-label="병원명" />
            <col className="w-[100px]" data-label="대상국가" />
            <col className="w-[400px]" data-label="이벤트 정보" />
            <col className="w-[100px]" data-label="처리상태" />
            <col className="w-[120px]" data-label="처리일시" />
            <col className="w-[100px]" data-label="반려사유" />
            <col className="w-[120px]" data-label="버튼" />
          </colgroup>
          <thead className="border-b-2 border-black text-[14px]">
            <tr className="h-[40px]">
              {["요청일시", "병원명", "대상국가", "이벤트 정보", "처리상태", "처리일시", "반려사유", ""].map(
                (title) => (
                  <td
                    key={`consultant-${title || "button"}`}
                    className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
                  >
                    {title}
                  </td>
                )
              )}
            </tr>
          </thead>
          <tbody>
            <tr className="h-[40px] border-b">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>
                <figure className="flex items-center gap-[10px] p-[6px]">
                  <img src="" alt="" className="aspect-square bg-sky-300" width={80} height={80} />
                  <figcaption className="line-clamp-2 text-left leading-[1]">
                    test et teet tet ete teetteetteetteetteetteetteetteet
                  </figcaption>
                </figure>
              </td>
              <td>
                <div>
                  <span className="inline-block aspect-square w-[10px] rounded-full bg-green-400" />
                  <p className="ml-[5px] inline-block">승인</p>
                </div>
              </td>
              <td>-</td>
              <td>-</td>
              <td className="text-left">
                <Link to={"/event/approval/1"}>
                  <span className="mb-[5px] rounded-md border border-black bg-gray-200 px-[10px] py-[6px]">
                    승인요청상세보기
                  </span>
                </Link>
              </td>
            </tr>
            <tr className="h-[40px] border-b">
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>
                <figure className="flex items-center gap-[10px] p-[6px]">
                  <img src="" alt="" className="aspect-square bg-sky-300" width={80} height={80} />
                  <figcaption className="line-clamp-2 text-left leading-[1]">
                    test et teet tet ete teetteetteetteetteetteetteetteet
                  </figcaption>
                </figure>
              </td>
              <td>
                <div>
                  <span className="inline-block aspect-square w-[10px] rounded-full bg-red-400" />
                  <p className="ml-[5px] inline-block">반려</p>
                </div>
              </td>
              <td>-</td>
              <td>
                <button
                  type="button"
                  className="mb-[5px] rounded-md bg-red-500 px-[10px] py-[6px] text-white"
                  onClick={() => dialogRef.current?.showModal()}
                >
                  사유
                </button>
              </td>
              <td className="text-left">
                <button
                  type="button"
                  className="mb-[5px] rounded-md border border-black bg-gray-200 px-[10px] py-[6px]"
                >
                  승인요청상세보기
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <dialog ref={dialogRef} className="rounded-xl">
        <form method="dialog" className="w-[900px]">
          {/* head */}
          <div className="m-[10px] flex h-[50px] items-start justify-between">
            <div className="flex gap-[20px] self-end">
              <img src="" alt="miin" />
              <h3 className="inline-block text-[20px]">반려사유</h3>
            </div>
            <button>
              <IconClose />
            </button>
          </div>
          {/* content */}
          <div className="mx-[16px] my-[20px] h-[400px] overflow-auto">
            <div className="mb-[16px] bg-purple-500 px-[20px] py-[16px] text-white">
              <p>미인은 의료법위반 사례 및 운영정책을 기반으로 검수 시스템을 운영합니다.</p>
              <p>
                고객에게 공정하고 투명한 광고정보가 제공될 수 있도록 법령 준수 여부를 확인하시여 광고 콘텐츠를 수정 및
                보완해 주시길 바랍니다.
              </p>
            </div>
            <div>
              <p>지난번 반려 시 안내드린 일부 미반영으로 반려 사유가 확인되어 안내드립니다.</p>
              <br />
              <p>[반려사항]</p>
              <p>1.컨텐츠 관리 기준</p>
              <p>
                -마취비가 부과되는 시술/수술일 경우, 마취 비용을 포함한 실 결제금액으로 표기 및 마취 비용 포함 여부
                기재(사용 예 : 마취비 포함, 수면마취비용 포함 등)
              </p>
              <p>-마취비가 환자의 선택에 따라 부과되는 경우 '마취 선택 시 N만원 추가 비용 발생'등의 문구 기재 필요</p>
              <p>
                -사후관리비가 부과되는 시술/수술일 경우, 사후관리비용을 포함한 실 결제 금액을 표기 및 사후관리비용 포함
                여부 기재(사용 예 : 사후관리비 포함 등)
              </p>
              <p>
                -사후관리비가 환자의 선택에 따라 부과되는 경우 '사후관리 선택 시 N만원 추가 비용 발생'등의 문구 기재
                필요
              </p>
              <p>{"<수정위치>"}</p>
              <p>가격정보 내 사후관리비 '없음'체크</p>
              <p>옵션1번 옵션구성시술 리터치:포함</p>
              <span className="inline-block aspect-square w-[400px] bg-green-300">이미지</span>
            </div>
          </div>
        </form>
      </dialog>
    </div>
  );
}
