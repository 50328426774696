import React, { useRef, useState } from "react";
import { Link, useLoaderData, useSearchParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import Nav from "@/components/nav";
import { Pagination } from "@/components/pagination";
import { hospitalFetch, userFetch } from "@/api";

import TABLE from "./utils/table.json";
import { ImageViewModal, RegistModal } from "./components";

const hospitalListQuery = (searchParams: URLSearchParams) => ({
  queryKey: ["hospital", "list", searchParams.toString() || "page=1"],
  queryFn: async () => {
    const newSearchParams = new URLSearchParams();

    const page = searchParams.get("page");

    const searchType = searchParams.get("st");
    const searchValue = searchParams.get("sv");

    if (page) newSearchParams.set("page", `${+page - 1}`);

    if (searchType && searchValue) newSearchParams.set(searchType, searchValue);

    const response = await hospitalFetch.get(newSearchParams.toString());

    return response.data;
  }
});

export const HospitalListLoader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    const url = new URL(request.url);

    return queryClient.ensureQueryData(hospitalListQuery(url.searchParams));
  };

export default function HospitalListPage() {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const registDialog = useRef<HTMLDialogElement>(null);
  const imageDialog = useRef<HTMLDialogElement>(null);

  const [hospitalId, setHospitalId] = useState<string | null>(null);

  const { data: hospitalList } = useQuery<any>({
    ...hospitalListQuery(searchParams),
    initialData: loaderData
  });

  const { data: userData } = useQuery<any>({
    queryKey: ["user", "list"],
    queryFn: async () => {
      const response = await userFetch.get();

      return response.data.items;
    }
  });

  const patchMutation = useMutation({
    mutationFn: async ({ type, id, body }: { type: "state" | "manager" | "allow"; id: string; body: any }) =>
      hospitalFetch.patch(type, id, body),
    onSuccess: () => {
      alert("Success");

      queryClient.invalidateQueries({ queryKey: ["hospital", "list"] });
    },
    onError: () => {
      alert("failed");
    }
  });
  const registMutation = useMutation({
    mutationFn: async (item: any) => hospitalFetch.post(item),
    onSuccess: () => {
      alert("Success");

      const dialogEl = registDialog.current;
      if (dialogEl) {
        const formEl = dialogEl.querySelector("form") as HTMLFormElement;
        formEl.reset();
      }

      queryClient.invalidateQueries({ queryKey: ["hospital", "list"] });
    },
    onError: () => {
      alert("failed");
    }
  });

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const type = formData.get("search_type")?.toString();
    const value = formData.get("search_value")?.toString();

    if (type && value) setSearchParams({ st: type, sv: value });
  };

  const handleOpenAllowChange = (e: React.FormEvent<HTMLFieldSetElement>) => {
    const radioInput = e.target as HTMLInputElement;
    const openAllow = radioInput.value as string;
    const hospitalId = e.currentTarget.dataset.hospitalId as string;

    patchMutation.mutate({ type: "allow", id: hospitalId, body: { is_open_allow: openAllow } });
  };

  const handleManagerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const adminId = e.currentTarget.value;
    const hospitalId = e.currentTarget.dataset.hospitalId as string;

    patchMutation.mutate({ type: "manager", id: hospitalId, body: { admin_user_id: adminId } });
  };

  const handleRegistDialog = () => {
    const dialogEl = registDialog.current;
    if (dialogEl) dialogEl.open ? dialogEl.close() : dialogEl.showModal();
  };

  const handleRegistDialogSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    registMutation.mutate({
      text: [
        {
          local: "ko",
          title: formData.get("title")
        }
      ]
    });
  };

  return (
    <div>
      <Nav list={[{ title: "병원관리", link: null }]} />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <h2 className="mb-[20px] text-[18px]">가입된 병원 목록</h2>

        <div className="w-max bg-white text-black">
          <div className="mb-[10px] flex items-center justify-between">
            <button
              type="button"
              className="block h-[30px] w-[100px] rounded-md bg-green-800 text-white"
              onClick={handleRegistDialog}
            >
              병원 등록하기
            </button>
            <form onSubmit={handleSearchSubmit}>
              <select
                className="rounded border p-[10px]"
                name="search_type"
                defaultValue={searchParams.get("st") ?? ""}
                required
              >
                <option hidden value="">
                  검색
                </option>
                <option value="state">등록상태</option>
                <option value="medical">진료과목</option>
                <option value="title">병원명</option>
                <option value="contact">병원 담당자 정보</option>
                <option value="admin">미인 담당자</option>
                <option value="allow">노출상태</option>
              </select>
              <input
                className="mx-[8px] w-[300px] rounded border p-[10px]"
                type="text"
                name="search_value"
                defaultValue={searchParams.get("sv") ?? ""}
                required
              />
              <input
                className="w-[60px] cursor-pointer rounded-md bg-green-600 p-[10px] text-white"
                type="submit"
                value="검색"
              />
            </form>
          </div>
          <table className="mb-[60px] border-t text-center">
            <colgroup>
              {TABLE.map((item) => (
                <col key={`hospital-list-col-group-${item.text}`} className={item.class} />
              ))}
            </colgroup>
            <thead className="border-b-2 border-black text-[14px]">
              <tr className="h-[40px]">
                {TABLE.map((item) => (
                  <td
                    key={`hospital-list-table-head-${item.text}`}
                    className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
                  >
                    {item.text}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {hospitalList.items.map((item: any) => (
                <tr key={"hospital-list-" + item.hospital_id} className="h-[40px] border-b">
                  <td className="relative">
                    <div className="absolute left-1/2 top-1/2 flex h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-md border-2 border-purple-400 bg-orange-100">
                      {item.state === "WAIT" && "등록대기"}
                      {item.state === "COMPLETE" && "등록완료"}
                    </div>
                  </td>
                  <td>
                    <fieldset
                      className="flex justify-evenly"
                      data-hospital-id={item.hospital_id}
                      onChange={handleOpenAllowChange}
                    >
                      <label className="leading-[12px]">
                        <input
                          type="radio"
                          className="mr-[5px] aspect-square w-[12px] appearance-none rounded-full border-2 border-black bg-white align-middle checked:bg-green-300"
                          name={"hospital-" + item.hospital_id}
                          value="true"
                          defaultChecked={item.is_open_allow}
                        />
                        <span className="align-middle">게시</span>
                      </label>
                      <label className="leading-[12px]">
                        <input
                          type="radio"
                          className="mr-[5px] aspect-square w-[12px] appearance-none rounded-full border-2 border-black bg-white align-middle checked:bg-green-300"
                          name={"hospital-" + item.hospital_id}
                          value="false"
                          defaultChecked={!item.is_open_allow}
                        />
                        <span className="align-middle">대기</span>
                      </label>
                    </fieldset>
                  </td>
                  <td className="relative">
                    <select
                      className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                      value={item.admin_user_id}
                      data-hospital-id={item.hospital_id}
                      onChange={handleManagerChange}
                    >
                      {userData &&
                        userData.map((admin: any) => (
                          <option key={`admin-list-${admin.admin_user_id}`} value={admin.admin_user_id}>
                            {admin.name}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>{item.medicals.join(", ") || "-"}</td>
                  <td className="relative">
                    <Link
                      to={`/hospital/regist/info/${item.hospital_id}`}
                      className="absolute left-0 top-0 h-full w-full content-center"
                    >
                      {item.title}
                    </Link>
                  </td>
                  <td>{item.address || "-"}</td>
                  <td className="relative">
                    <Link
                      to={`/hospital/regist/doctor/${item.hospital_id}`}
                      className="absolute left-1/2 top-1/2 h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 place-content-center rounded-md border border-purple-500 hover:bg-purple-500 hover:text-white"
                    >
                      의사정보 보기
                    </Link>
                  </td>
                  <td className="relative">
                    <button
                      type="button"
                      className="absolute left-1/2 top-1/2 h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 rounded-md border border-purple-500 hover:bg-purple-500 hover:text-white"
                      value={item.hospital_id}
                      onClick={(e) => {
                        const id = e.currentTarget.value;
                        setHospitalId(id);
                        imageDialog.current?.showModal();
                      }}
                    >
                      이미지 보기
                    </button>
                  </td>
                  <td className="relative">
                    <Link
                      to={`/hospital/regist/contact/${item.hospital_id}`}
                      className="absolute left-1/2 top-1/2 h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 place-content-center rounded-md border border-purple-500 hover:bg-purple-500 hover:text-white"
                    >
                      연락처정보 보기
                    </Link>
                  </td>
                  <td>{item.contact_phone ?? "-"}</td>
                  <td>{item.contact_name ?? "-"}</td>
                  <td>{item.create_date ? dayjs.tz(item.create_date).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination totalItem={(hospitalList as any).paging_info.total_item_count} viewCount={10} />
        </div>
      </div>
      <RegistModal
        ref={registDialog}
        handleRegistDialog={handleRegistDialog}
        handleRegistDialogSubmit={handleRegistDialogSubmit}
      />
      <ImageViewModal ref={imageDialog} hospitalId={hospitalId} />
    </div>
  );
}
