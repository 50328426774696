import { hospitalDetailFetch } from "@/api";
import { IconClose } from "@/components/icons";
import { useQuery } from "@tanstack/react-query";
import React, { forwardRef, useState } from "react";
import { Link } from "react-router-dom";

interface PropsType {
  hospitalId: string | null;
}

const LocaleData = [
  {
    key: "image-modal-nav-ko",
    value: "ko",
    text: "Korean"
  },
  {
    key: "image-modal-nav-en",
    value: "en",
    text: "English"
  },
  {
    key: "image-modal-nav-ja",
    value: "ja",
    text: "Japaness"
  },
  {
    key: "image-modal-nav-vi",
    value: "vi",
    text: "Vietnam"
  }
];

export default forwardRef<HTMLDialogElement, PropsType>(function ImageViewModal(props, ref) {
  const { hospitalId } = props;

  const [selectLocale, setSelectLocale] = useState("ko");

  const { data: detailData } = useQuery({
    queryKey: ["hospital", "info", hospitalId],
    queryFn: async () => {
      const response = await hospitalDetailFetch.get(hospitalId as string);

      return response.data;
    },
    enabled: hospitalId !== null
  });

  return (
    <dialog ref={ref} className="rounded-md">
      {detailData && (
        <form method="dialog" className="min-w-max">
          <div className="flex items-center bg-slate-700 px-[20px] py-[10px]">
            <h3 className="text-[20px] text-white">등록된 병원 이미지 보기</h3>
            <Link
              to={`/hospital/regist/info/${hospitalId}`}
              className="ml-[20px] rounded-md bg-orange-400 px-[10px] py-[4px] text-white"
            >
              수정
            </Link>
            <button className="ml-auto">
              <IconClose fill="white" />
            </button>
          </div>
          <div className="mx-[20px] mt-[10px] border-b-2">
            {LocaleData.map((item) => (
              <button
                type="button"
                key={item.key}
                className="relative top-[2px] rounded-tl-md rounded-tr-md border-2 bg-stone-100 px-[20px] py-[5px] data-[active=true]:border-b-0 data-[active=true]:bg-white data-[active=true]:pb-[7px]"
                value={item.value}
                data-active={item.value === selectLocale}
                onClick={(e) => {
                  setSelectLocale(e.currentTarget.value);
                }}
              >
                {item.text}
              </button>
            ))}
          </div>
          <div className="m-[20px] grid grid-cols-[300px_1fr] grid-rows-4 gap-x-[30px] gap-y-[20px]">
            <div className="row-span-1 flex flex-col">
              <h4 className="mb-[5px]">병원 목록 썸네일</h4>
              <div className="pr-[2px]">
                {detailData[selectLocale] && detailData[selectLocale].thumb_image ? (
                  <img
                    src={detailData[selectLocale].thumb_image.image_url}
                    alt="병원 목록 썸네일"
                    className="aspect-video w-full rounded-md border"
                  />
                ) : (
                  <div className="aspect-video w-full rounded-md border-2 bg-stone-200 p-2">이미지가 없습니다.</div>
                )}
              </div>
            </div>
            <div className="col-span-1 row-span-3 flex flex-col">
              <h4 className="mb-[5px]">병원 대표 이미지</h4>
              <div className="h-0 max-h-full w-full flex-grow overflow-y-auto pr-[2px]">
                {detailData[selectLocale] && detailData[selectLocale].main_multi_image ? (
                  detailData[selectLocale].main_multi_image.map((imgItem: any) => (
                    <img
                      src={imgItem.image_url}
                      key={`multi-image-${imgItem.image_id}`}
                      className="mb-[5px] aspect-video w-full rounded-md border"
                      alt="병원 대표 이미지"
                    />
                  ))
                ) : (
                  <div className="h-full w-full rounded-md border-2 bg-stone-200 p-2">이미지가 없습니다.</div>
                )}
              </div>
            </div>
            <div className="col-start-2 row-span-4 row-start-1 flex flex-col">
              <h4 className="mb-[5px]">병원 상세 소개</h4>
              <div className="h-0 max-h-full min-w-[500px] flex-grow overflow-y-auto">
                {detailData[selectLocale] && detailData[selectLocale].des_image ? (
                  <img
                    src={detailData[selectLocale].des_image.image_url}
                    alt="병원 상세 소개"
                    className="w-full rounded-md border"
                  />
                ) : (
                  <div className="h-full w-full rounded-md border-2 bg-stone-200 p-2">이미지가 없습니다.</div>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </dialog>
  );
});
