import React from "react";
import { Link } from "react-router-dom";

import useHeaderHook from "./header.hook";
import { IconClock } from "@/components/icons";

export default function RootHeader() {
  const { expire, handleSetTimer, handleLogout } = useHeaderHook();

  return (
    <header className="col-start-1 -col-end-1 row-start-1 row-end-2 flex w-full items-center justify-between bg-gradient-to-r from-black from-10% via-[#404d56] to-[#404d56] px-[30px] py-[10px]">
      <Link to="/" className="h-[100%] w-auto bg-white">
        logo
      </Link>
      <div className="mr-[30px] flex h-[100%] rounded-full bg-white">
        <div className="relative flex items-center px-[20px] after:absolute after:right-0 after:block after:h-1/3 after:w-[2px] after:bg-cyan-900">
          미인
        </div>
        <div className="relative flex items-center px-[20px] after:absolute after:right-0 after:block after:h-1/3 after:w-[2px] after:bg-cyan-900">
          사업부
        </div>
        <div className="flex items-center px-[20px]">
          <IconClock fill="#0088ff" />
          <div className="ml-[8px] mr-[15px]">
            <span>{expire.min}</span>
            <span className="ml-[4px] mr-[4px]">:</span>
            <span>{expire.sec}</span>
          </div>
          <button type="button" onClick={handleSetTimer} value="test" className="mr-3 bg-yellow-300">
            타이머 종료 테스트
          </button>
          <button type="button" className="underline underline-offset-2" onClick={handleSetTimer} value="reset">
            연장
          </button>
        </div>
        <button type="button" className="flex items-center rounded-full bg-gray-300 px-[20px]" onClick={handleLogout}>
          로그아웃
        </button>
      </div>
    </header>
  );
}
