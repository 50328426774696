import React, { useEffect, useState } from "react";
import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { IconAngleDown, IconClose, IconInfo } from "@/components/icons";
import { eventApprovalFetch, eventDetailFetch } from "@/api";
import dayjs from "dayjs";

const eventDetailQuery = (eventId: string) => ({
  queryKey: ["event", "detail", eventId],
  queryFn: async () => {
    const response = await eventDetailFetch.get(eventId);

    return response.data;
  }
});

export const EventEditLoader =
  (queryClient: QueryClient) =>
  ({ params }: LoaderFunctionArgs) => {
    return queryClient.ensureQueryData(eventDetailQuery(params.event_id as string));
  };

export default function EventEditPage() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();

  const eventId = params.event_id as string;

  const [tags, setTags] = useState<any[]>([]);
  const [optionCount, setOptionCount] = useState(1);
  const [multiImage, setMultiImage] = useState<any>([]);

  const { data: eventDetail, isSuccess } = useQuery<any>({ ...eventDetailQuery(eventId), initialData: loaderData });

  const { data: eventList } = useQuery<any>({
    queryKey: ["event", "approval", "list", `hospital_id=${eventDetail?.hospital_id}`],
    queryFn: async () => {
      const response = await eventApprovalFetch.get(`hospital_id=${eventDetail?.hospital_id}`);

      return response.data.items;
    }
  });

  useEffect(() => {
    if (eventDetail) {
      setTags(eventDetail.tags.map((tag: any, index: number) => ({ index, ...tag })));
      setOptionCount(eventDetail.items.length);
      setMultiImage(eventDetail.detail_multi_image.map((img: any) => ({ ...img, state: "STAY" })));
    }
  }, [eventDetail]);

  const mutation = useMutation({
    mutationFn: async ({ id, body }: any) => eventDetailFetch.put(id, body),
    onSuccess: () => {
      alert("good");
      navigate("/event/list");
    },
    onError: () => {
      alert("failed");
    }
  });

  const handleRegistSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    const formData = new FormData();

    const baseEventId = data.get("base_event_id");

    if (baseEventId) formData.set("base_event_id", baseEventId);

    const local = data.get("local");

    if (local) formData.set("local", local);

    const title = data.get("title");

    if (title) formData.set("title", title);

    const openStartDate = data.get("open_start_date");

    if (openStartDate)
      formData.set(
        "open_start_date",
        dayjs
          .tz(openStartDate as string)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      );

    const openEndDate = data.get("open_end_date");

    if (openEndDate)
      formData.set(
        "open_end_date",
        dayjs
          .tz(openEndDate as string)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      );

    const gender = data.get("gender");

    if (gender) formData.set("gender", gender);

    if (tags.length) {
      tags.forEach((tag: any, index: number) => {
        if (tag.event_tag_id) formData.set(`tags[${index}].event_tag_id`, tag.event_tag_id);
        if (tag.state) formData.set(`tags[${index}].state`, tag.state);

        formData.set(`tags[${index}].description`, tag.description);
      });
    }

    const treatments = eventDetail.medical_category;

    if (treatments.length > 0) {
      treatments.forEach((treat: any, index: number) => {
        formData.set(`medical_category[${index}].m_c_i_id`, treat.m_c_i_id);
        formData.set(`medical_category[${index}].classify`, treat.classify);
        formData.set(`medical_category[${index}].state`, "STAY");
      });
    }

    const isVat = data.get("is_vat");

    if (isVat) formData.set("is_vat", isVat);

    const isAnesthesia = data.get("is_anesthesia");

    if (isAnesthesia) formData.set("is_anesthesia", isAnesthesia);

    const isAfter = data.get("is_after");

    if (isAfter) formData.set("is_after", isAfter);

    let index = 0;
    let option;

    do {
      option = data.getAll(`option${index}`);

      if (option.length < 1) break;

      const prevOption = eventDetail.items[index];

      if (prevOption) formData.set(`items[${index}].event_item_id`, eventDetail.items[index].event_item_id);

      formData.set(`items[${index}].item_title`, option[0]);
      formData.set(`items[${index}].price`, option[1]);
      formData.set(`items[${index}].discount`, option[2]);

      index++;
    } while (index < 10);

    const thumbnail = data.get("thumbnail") as File;

    if (thumbnail.size > 0) {
      formData.set("thumb_big_image.image_id", eventDetail.thumb_big_image.image_id);
      formData.set("thumb_big_image.image", thumbnail);
      formData.set("thumb_big_image.state", "UPDATE");
    } else {
      formData.set("thumb_big_image.image_id", eventDetail.thumb_big_image.image_id);
      formData.set("thumb_big_image.state", "STAY");
    }

    const thumbnailVertical = data.get("thumbnail-2") as File;

    if (thumbnailVertical.size > 0) {
      formData.set("thumb_small_image.image_id", eventDetail.thumb_small_image.image_id);
      formData.set("thumb_small_image.image", thumbnailVertical);
      formData.set("thumb_small_image.state", "UPDATE");
    } else {
      formData.set("thumb_small_image.image_id", eventDetail.thumb_small_image.image_id);
      formData.set("thumb_small_image.state", "STAY");
    }

    if (multiImage) {
      multiImage.forEach((img: any, index: number) => {
        if (img.image_id) {
          formData.set(`detail_multi_image[${index}].image_id`, img.image_id);
        }
        if (img.state === "INSERT") {
          formData.set(`detail_multi_image[${index}].image`, img.file);
        }
        formData.set(`detail_multi_image[${index}].state`, img.state);
      });
    }

    const introImage = data.get("intro-image") as File;

    if (introImage.size > 0) {
      formData.set("des_image.image_id", eventDetail.des_image.image_id);
      formData.set("des_image.image", introImage);
      formData.set("des_image.state", "UPDATE");
    } else {
      formData.set("des_image.image_id", eventDetail.des_image.image_id);
      formData.set("des_image.state", "STAY");
    }

    const description = data.get("description");

    if (description) formData.set("description", description);

    mutation.mutate({ id: eventId, body: formData });
  };

  if (!isSuccess) throw new Error("EVENT EDIT ERROR");
  return (
    <div>
      <Nav
        list={[
          { title: "이벤트관리", link: "/event/list" },
          { title: "이벤트 수정", link: null }
        ]}
      />
      <div className="h-full bg-white px-[30px] py-[20px]">
        {/* 제목 */}
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h1 className="text-[18px] font-semibold">{`${eventDetail.title} 이벤트 수정`}</h1>
        </div>
        {/* 컨텐츠 */}
        <form className="flex w-[1000px] flex-col gap-[10px]" onSubmit={handleRegistSubmit}>
          {/* 1. 이벤트 연결 */}
          <div className="rounded-xl border-2">
            {/* 1. title */}
            <div className="border-b-2 px-[15px]">
              <h2 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 연결</h2>
            </div>
            {/* 1. content */}
            <div className="p-[20px]">
              <div>
                <h3 className="mb-[8px] block text-purple-500">연결할 이벤트</h3>
                {eventList && (
                  <div className="relative w-[80%]">
                    <select
                      className="h-[40px] w-full appearance-none rounded-md border px-[10px] invalid:text-gray-400"
                      name="base_event_id"
                      defaultValue={eventDetail.base_event_id ?? ""}
                      onClick={(e) => {
                        const childCount = e.currentTarget.childElementCount;
                        if (childCount > 1) return;
                        alert("연결할 이벤트가 없습니다.");
                      }}
                    >
                      <option value="" hidden>
                        이벤트 검색
                      </option>
                      {eventList.map((listItem: any) => (
                        <option key={`relative-event-${listItem.event_id}`} value={listItem.event_id}>
                          {listItem.event_title}
                        </option>
                      ))}
                    </select>
                    <IconAngleDown className="absolute right-[10px] top-1/2 -translate-y-1/2" />
                  </div>
                )}
              </div>
              <div className="mb-[20px] mt-[5px] flex gap-[8px]">
                <IconInfo fill="yellowgreen" />
                <div>
                  <p>같은 시술의 이벤트를 다른 국가의 고객에게 제공하는 경우, 이벤트를 연결할 수 있습니다.</p>
                  <p>단, 연결할 이벤트의 시술형 태그는 동일해야 합니다.</p>
                </div>
              </div>
            </div>
          </div>
          {/* 2. event category */}
          <div className="rounded-xl border-2">
            <div className="border-b-2 px-[15px]">
              <h2 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 분류</h2>
            </div>
            <div className="p-[20px]">
              <h3 className="label-required mb-[8px] inline-block text-purple-500">대상 국가</h3>
              <fieldset className="flex gap-[12px]">
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="local"
                    defaultChecked={eventDetail.local === "KO"}
                    value="KO"
                    required
                  />
                  <span className="align-middle">한국(한국어)</span>
                </label>
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="local"
                    defaultChecked={eventDetail.local === "JA"}
                    value="JA"
                    required
                  />
                  <span className="align-middle">일본(일본어)</span>
                </label>
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="local"
                    defaultChecked={eventDetail.local === "EN"}
                    value="EN"
                    required
                  />
                  <span className="align-middle">영어권(영어)</span>
                </label>
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="local"
                    defaultChecked={eventDetail.local === "VI"}
                    value="VI"
                    required
                  />
                  <span className="align-middle">베트남(베트남어)</span>
                </label>
              </fieldset>
            </div>
          </div>
          {/* 3. event info */}
          <div className="rounded-xl border-2">
            {/* 제목 */}
            <div className="border-b-2 px-[15px]">
              <h2 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 정보</h2>
            </div>
            {/* 이벤트 제목 */}
            <div className="p-[20px]">
              <h3 className="label-required mb-[8px] inline-block text-purple-500">이벤트제목</h3>
              <input
                type="text"
                className="block h-[40px] w-[80%] rounded-md border-2 px-[10px]"
                name="title"
                placeholder="예: 사각턱보톡스 리즈톡스 50유닛,자연유착 쌍커풀"
                pattern="^[\p{L}\p{N}&-_,.\s]+$"
                defaultValue={eventDetail.title}
                required
              />
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="red" />
                <p className="text-red-500">비용을 입력하지 마세요. &-_,.를 제외한 다른 특수문자 입력 하지 마세요.</p>
              </div>
            </div>
            {/* 이벤트 기간 */}
            <div className="p-[20px]">
              <h3 className="mb-[8px] inline-block text-purple-500">이벤트 기간</h3>
              <div className="flex gap-[40px]">
                <div>
                  <h3 className="label-required mb-[8px] inline-block text-purple-500">시작일</h3>
                  <input
                    type="date"
                    className="block h-[40px] w-[200px] rounded-md border-2 px-[10px]"
                    name="open_start_date"
                    defaultValue={dayjs.utc(eventDetail.open_start_date).tz().format("YYYY-MM-DD")}
                    required
                  />
                </div>
                <div>
                  <div>
                    <h3 className="label-required mb-[8px] mr-[15px] inline-block text-purple-500">종료일</h3>
                    <span
                      className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                      title="종료일을 설정하면 시작일과 종료일이 노출돼요"
                    >
                      i
                    </span>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      className="mr-[5px]"
                      name="endDate"
                      value="use"
                      defaultChecked={!eventDetail.open_end_date}
                    />
                    <label className="mr-[20px]">없음</label>
                    <input
                      type="radio"
                      className="mr-[5px]"
                      name="endDate"
                      value="unUse"
                      defaultChecked={!!eventDetail.open_end_date}
                      onChange={(e) =>
                        ((document.querySelector("input[type=date][name=open_end_date]") as HTMLInputElement).disabled =
                          false)
                      }
                    />
                    <label className="mr-[20px]">종료일 설정</label>

                    <input
                      type="date"
                      className="block h-[40px] w-[200px] rounded-md border-2 px-[10px]"
                      name="open_end_date"
                      defaultValue={dayjs.utc(eventDetail.open_end_date).tz().format("YYYY-MM-DD")}
                      disabled={
                        !(
                          document.querySelector(
                            "input[type='radio'][name='endDate'][value='unUse']"
                          ) as HTMLInputElement
                        )?.checked
                      }
                      required={
                        !(
                          document.querySelector(
                            "input[type='radio'][name='endDate'][value='unUse']"
                          ) as HTMLInputElement
                        )?.checked
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* 대상 성별 */}
            <div className="p-[20px]">
              <h4 className="label-required mb-[8px] inline-block text-purple-500">대상 성별</h4>
              <fieldset className="flex gap-[10px]">
                <label className="align-middle">
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="gender"
                    value={"NONE"}
                    defaultChecked={eventDetail.gender === "NONE"}
                    required
                  />
                  남여공용
                </label>
                <label className="align-middle">
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="gender"
                    value={"WOMAN"}
                    defaultChecked={eventDetail.gender === "WOMAN"}
                    required
                  />
                  여성
                </label>
                <label className="align-middle">
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="gender"
                    value={"MAN"}
                    defaultChecked={eventDetail.gender === "MAN"}
                    required
                  />
                  남성
                </label>
              </fieldset>
            </div>
            {/* 자유 태그 */}
            <div className="p-[20px]">
              <h3 className="label-required mb-[8px] inline-block text-purple-500">자유태그</h3>
              <div className="flex w-[80%] flex-wrap gap-[5px] rounded-md border-2 px-[10px] py-[5px]">
                {tags
                  .filter((tag) => tag.state !== "DELETE")
                  .map((tag) => (
                    <div
                      key={`tags-${tag.index}-${Math.floor(Math.random() * 1000)}`}
                      className="flex h-[40px] items-center gap-[10px] rounded-md bg-stone-200 px-[10px]"
                    >
                      <label>
                        <input type="text" className="sr-only" name="tags" defaultValue={tag.description} readOnly />
                        {tag.description}
                      </label>
                      <button
                        type="button"
                        value={tag.index}
                        onClick={(e) => {
                          const index = +e.currentTarget.value;

                          setTags(
                            tags
                              .map((prevTag) => {
                                if (prevTag.index === index) {
                                  if (prevTag.event_tag_id) {
                                    return {
                                      ...prevTag,
                                      state: "DELETE"
                                    };
                                  }
                                  return null;
                                }
                                return prevTag;
                              })
                              .filter(Boolean)
                          );
                        }}
                      >
                        <IconClose />
                      </button>
                    </div>
                  ))}
                <input
                  type="text"
                  className="h-[40px] flex-grow px-[5px]"
                  placeholder="단어를 입력하고 엔터키를 누르면 등록됩니다."
                  onKeyDown={(e) => {
                    if (e.nativeEvent.isComposing) return;

                    const text = e.currentTarget.value;
                    if (e.key === "Enter") {
                      e.currentTarget.value = "";
                      e.preventDefault();

                      if (text.trim()) {
                        setTags((prev) => [...prev, { index: prev.length + 1, description: text }]);
                      }
                    }
                  }}
                />
              </div>
              <div className="mb-[20px] mt-[5px] flex gap-[8px]">
                <IconInfo fill="red" />
                <p className="text-red-500">이벤트와 관련없는 태그는 통보없이 삭제될 수 있습니다.</p>
              </div>
            </div>
          </div>
          {/* 4. price info */}
          <div className="rounded-xl border-2">
            {/* 제목 */}
            <div className="border-b-2 px-[15px]">
              <h2 className="h-[40px] text-[14px] font-medium leading-[40px]">가격 정보</h2>
            </div>
            {/* VAT */}
            <div className="p-[20px]">
              <div>
                <h3 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">VAT</h3>
                <span
                  className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                  title="예시) 치아교정, 스케일링등 미용목적이 아닌 시술만 VAT 비대상 진료 가능"
                >
                  i
                </span>
              </div>
              <div className="flex gap-[20px]">
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="is_vat"
                    value="true"
                    defaultChecked={eventDetail.is_vat}
                    required
                  />
                  <span className="align-middle">포함</span>
                </label>
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="is_vat"
                    value="false"
                    defaultChecked={!eventDetail.is_vat}
                    required
                  />
                  <span className="align-middle">비대상</span>
                </label>
              </div>
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="black" />
                <p>VAT가 부과되는 시술은 VAT가 포함된 가격으로 입력해 주세요.</p>
              </div>
            </div>
            {/* 마취비 */}
            <div className="p-[20px]">
              <div>
                <h3 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">마취비</h3>
                <span
                  className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                  title="예시) 가슴성형수술의 경우 포함, 레이저리프팅의 경우 없음"
                >
                  i
                </span>
              </div>
              <div className="flex gap-[20px]">
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="is_anesthesia"
                    value="true"
                    defaultChecked={eventDetail.is_anesthesia}
                    required
                  />
                  <span className="align-middle">포함</span>
                </label>
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="is_anesthesia"
                    value="false"
                    defaultChecked={!eventDetail.is_anesthesia}
                    required
                  />
                  <span className="align-middle">없음</span>
                </label>
              </div>
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="black" />
                <p>
                  [없음]은 추가 비용이 드는 수면마취가 필수적이지 않은 경우에만 가능해요. 이를 어길시 패널티가 적용될 수
                  있어요.
                </p>
              </div>
            </div>
            {/* 사후관리비 */}
            <div className="p-[20px]">
              <div>
                <h3 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">사후관리비</h3>
                <span
                  className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                  title="예시) 가슴성형수술의 경우 포함, 레이저리프팅의 경우 없음"
                >
                  i
                </span>
              </div>
              <div className="flex gap-[20px]">
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="is_after"
                    value="true"
                    defaultChecked={eventDetail.is_after}
                    required
                  />
                  <span className="align-middle">포함</span>
                </label>
                <label>
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="is_after"
                    value="false"
                    defaultChecked={!eventDetail.is_after}
                    required
                  />
                  <span className="align-middle">없음</span>
                </label>
              </div>
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="black" />
                <p>[없음]은 사후관리가 필수적이지 않은 경우에만 가능해요. 이를 어길시 패널티가 적용될 수 있어요.</p>
              </div>
            </div>
          </div>
          {/* 5. option - (template later) */}
          {Array.from({ length: optionCount }).map((_, index) => (
            <div key={`option-${index}`} className="rounded-xl border-2">
              {/* 제목 */}
              <div className="border-b-2 px-[15px]">
                <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">{`옵션 ${index + 1}`}</h3>
              </div>
              {/* 컨텐츠 */}
              <div className="flex gap-[20px] p-[20px]">
                {/* 옵션명 */}
                <div>
                  <div>
                    <h4 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">옵션명</h4>
                  </div>
                  <div className="relative w-fit">
                    <input
                      type="text"
                      className="h-[40px] w-[500px] rounded-md border-2 px-[10px]"
                      name={`option${index}`}
                      placeholder="예: 사각턱보톡스 리즈톡스 50유닛 (사각턱,팔자주름 부위 택1)"
                      maxLength={40}
                      defaultValue={eventDetail.items[index]?.item_title}
                      required
                      onChange={(e) => {
                        const el = e.currentTarget;
                        const textLength = el.value.length;

                        const nextEl = el.nextElementSibling as HTMLElement;

                        nextEl.innerHTML = `${textLength} / 40`;
                      }}
                    />
                    <span className="absolute bottom-[10px] right-[10px] text-gray-400">0 / 40</span>
                  </div>
                </div>
                <fieldset
                  className="flex gap-[20px]"
                  onChange={(e) => {
                    const el = e.target as HTMLInputElement;

                    const optionName = el.name;

                    const parent = e.currentTarget;

                    const price = +(
                      parent.querySelector(`input[name=${optionName}][data-type=price]`) as HTMLInputElement
                    ).value;
                    const discount = +(
                      parent.querySelector(`input[name=${optionName}][data-type=discount]`) as HTMLInputElement
                    ).value;

                    const rateEl = parent.querySelector(`span#${optionName}`) as HTMLSpanElement;

                    const isValueNaN = isNaN(price) || isNaN(discount);

                    if (isValueNaN) {
                      alert("입력 값이 정확하지 않습니다.");
                      return;
                    }

                    const discountRate = 100 - (discount / price) * 100;

                    rateEl.innerHTML = `${discountRate.toFixed(2)}%`;
                  }}
                >
                  {/* 정상가 */}
                  <div>
                    <div>
                      <h4 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">정상가</h4>
                    </div>
                    <div className="flex h-[40px] w-[160px] rounded-md border-2">
                      <input
                        type="text"
                        className="w-0 flex-1 border-r-2 px-[5px]"
                        name={`option${index}`}
                        data-type="price"
                        pattern="[\d]+"
                        defaultValue={eventDetail.items[index]?.price}
                        required
                      />
                      <span className="block aspect-square h-full content-center bg-gray-100 text-center">원</span>
                    </div>
                  </div>
                  {/* 할인가 */}
                  <div>
                    <div>
                      <h4 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">할인가</h4>
                    </div>
                    <div className="flex h-[40px] w-[160px] rounded-md border-2">
                      <input
                        type="text"
                        className="w-0 flex-1 border-r-2 px-[5px]"
                        name={`option${index}`}
                        data-type="discount"
                        pattern="[\d]+"
                        defaultValue={eventDetail.items[index]?.discount}
                        required
                      />
                      <span className="block aspect-square h-full content-center bg-gray-100 text-center">원</span>
                    </div>
                  </div>
                  {/* 할인율 */}
                  <div>
                    <div>
                      <h4 className="label-required mb-[8px] mr-[12px] inline-block text-purple-500">할인율</h4>
                    </div>
                    <div className="flex h-[40px] w-[80px] items-center justify-end rounded-md border-2 bg-gray-100 px-[10px]">
                      <span id={`option${index}`} className="text-purple-500">
                        {(() => {
                          const price = eventDetail.items[index]?.price;

                          if (!price) return "0%";

                          const discount = eventDetail.items[index]?.discount;

                          const isValueNaN = isNaN(price) || isNaN(discount);

                          if (isValueNaN) {
                            alert("입력 값이 정확하지 않습니다.");
                            return;
                          }

                          const discountRate = 100 - (discount / price) * 100;

                          return `${discountRate.toFixed(2)}%`;
                        })()}
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          ))}
          {/* 6. add option btn */}
          <div>
            <button
              type="button"
              className="block h-[40px] w-full rounded-md bg-red-600 text-white"
              onClick={() => {
                setOptionCount((prev) => prev + 1);
              }}
            >
              옵션추가
            </button>
          </div>
          {/* 7. image */}
          <div className="rounded-xl border-2">
            {/* title */}
            <div className="border-b-2 px-[15px]">
              <h2 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 이미지</h2>
            </div>
            {/* thumbnail */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              {/* 제목 */}
              <div className="mb-[10px]">
                <h3 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                  이벤트 썸네일 이미지 (1080X1080)
                </h3>
                <label className="cursor-pointer rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="thumbnail"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files;
                      const name = e.currentTarget.name;
                      const reader = new FileReader();

                      if (file) {
                        reader.readAsDataURL(file[0]);
                        reader.onload = (a) => {
                          const el = document.querySelector(`img[data-type=${name}`) as HTMLImageElement;
                          const dataUrl = a.target?.result as string;

                          el.src = dataUrl;
                        };
                      }
                    }}
                  />
                </label>
              </div>
              {/* 이미지 뷰 */}
              <div>
                <div className="relative mb-1 w-[100px] rounded-md border-2 bg-green-200">
                  <img
                    src={eventDetail.thumb_big_image.image_url}
                    alt="event square thumbnail"
                    data-type="thumbnail"
                    className="aspect-square"
                  />
                  <button
                    className="absolute right-2 top-1"
                    onClick={(e) => {
                      const imgEl = e.currentTarget.previousElementSibling as HTMLImageElement;
                      const inputEl = document.querySelector("input[type=file][name=thumbnail]") as HTMLInputElement;

                      imgEl.src = "";

                      inputEl.files = null;
                      inputEl.value = "";
                      inputEl.required = true;
                    }}
                  >
                    <IconClose width={12} heigth={12} />
                  </button>
                </div>
              </div>

              {/* footer */}
              <div className="flex gap-[10px]">
                <IconInfo fill="green" />
                <span>1080px * 1080px, 최대 2M, 이벤트를 대표하는 이미지를 등록해 주세요.</span>
              </div>
            </div>
            {/* 썸네일 2 878X1080 */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              {/* head */}
              <div className="mb-[10px]">
                <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                  이벤트 썸네일 이미지 (878X1080)
                </h4>
                <label className="cursor-pointer rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="thumbnail-2"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files;
                      const name = e.currentTarget.name;
                      const reader = new FileReader();

                      if (file) {
                        reader.readAsDataURL(file[0]);
                        reader.onload = (a) => {
                          const el = document.querySelector(`img[data-type=${name}`) as HTMLImageElement;
                          const dataUrl = a.target?.result as string;

                          el.src = dataUrl;
                        };
                      }
                    }}
                  />
                </label>
              </div>
              {/* content */}
              <div>
                <div className="relative mb-1 w-[100px] rounded-md border-2 bg-green-200">
                  <img
                    src={eventDetail.thumb_small_image.image_url}
                    alt="event vertical thumbnail"
                    data-type="thumbnail-2"
                    className="aspect-[878/1080]"
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-1 bg-transparent"
                    onClick={(e) => {
                      const imgEl = e.currentTarget.previousElementSibling as HTMLImageElement;
                      const inputEl = document.querySelector("input[type=file][name=thumbnail-2]") as HTMLInputElement;

                      imgEl.src = "";

                      inputEl.files = null;
                      inputEl.value = "";
                      inputEl.required = true;
                    }}
                  >
                    <IconClose width={12} heigth={12} />
                  </button>
                </div>
              </div>
              {/* footer */}
              <div className="flex gap-[10px]">
                <IconInfo fill="green" />
                <span>878px * 1080px, 최대 2M, 이벤트를 대표하는 이미지를 등록해 주세요.</span>
              </div>
            </div>
            {/* detail top image */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              {/* head */}
              <div className="mb-[30px]">
                <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                  이벤트 상품 상세페이지 상단 이미지
                </h4>
                <label className="cursor-pointer rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="multiple"
                    multiple
                    accept="image/*"
                    onChange={(e) => {
                      const files = e.currentTarget.files;

                      if (!files) return;

                      const filesLength = files.length;

                      if (filesLength <= 0) return;

                      for (let i = 0; i < filesLength; i++) {
                        const reader = new FileReader();

                        reader.readAsDataURL(files[i]);

                        reader.onload = (readE) => {
                          const imgUrl = readE.target?.result as string;

                          const hasImage = multiImage.findIndex((item: any) => item.image_url === imgUrl);

                          if (hasImage !== -1) {
                            alert("이미 존재하는 이미지입니다.");
                            return;
                          }

                          setMultiImage((prev: any) => [
                            ...prev,
                            { image_url: imgUrl, file: files[i], state: "INSERT" }
                          ]);
                        };
                      }
                    }}
                  />
                </label>
              </div>
              {/* content */}
              <div className="mb-2 flex flex-wrap gap-2">
                {multiImage.length > 0 &&
                  multiImage
                    .filter((img: any) => img.state !== "DELETE")
                    .map((item: any, index: number) => (
                      <div key={`multiple-image-${index}`} className="relative w-[300px] bg-green-200">
                        <img src={item.image_url} alt={`multi-${index}`} className="aspect-[1080/468] rounded-md" />
                        <button
                          type="button"
                          className="absolute right-2 top-1"
                          value={item.image_url}
                          onClick={(e) => {
                            const url = e.currentTarget.value;

                            const deleteImage = multiImage
                              .map((prevItem: any) => {
                                if (prevItem.image_url === url) {
                                  if (prevItem.image_id) {
                                    return {
                                      ...prevItem,
                                      state: "DELETE"
                                    };
                                  }
                                  return null;
                                }
                                return prevItem;
                              })
                              .filter(Boolean);

                            if (deleteImage < 1) {
                              const el = document.querySelector("input[name=multiple") as HTMLInputElement;
                              el.files = null;
                              el.value = "";
                              el.required = true;
                            }
                            setMultiImage(deleteImage);
                          }}
                        >
                          <IconClose width={12} heigth={12} />
                        </button>
                      </div>
                    ))}
              </div>
              {/* footer */}
              <div className="flex gap-[10px]">
                <IconInfo fill="green" />
                <span>1080px * 468px, 개당 최대 2M 씩, 한개 이상등록 가능</span>
              </div>
            </div>
            {/* product intro image */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              <div className="mb-[30px]">
                <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                  병원상세 소개 이미지
                </h4>
                <label className="inline rounded-md bg-green-700 px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="intro-image"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files;
                      const name = e.currentTarget.name;
                      const reader = new FileReader();

                      if (file) {
                        reader.readAsDataURL(file[0]);
                        reader.onload = (a) => {
                          const el = document.querySelector(`img[data-type=${name}]`) as HTMLImageElement;
                          const dataUrl = a.target?.result as string;

                          el.src = dataUrl;
                        };
                      }
                    }}
                  />
                </label>
              </div>
              <div>
                <div className="relative mb-2 h-[800px] w-[768px] overflow-y-scroll bg-green-200">
                  <img
                    src={eventDetail.des_image.image_url}
                    alt=""
                    className="rounded-md object-contain"
                    data-type="intro-image"
                  />
                  <button
                    type="button"
                    className="absolute right-[10px] top-[10px]"
                    onClick={(e) => {
                      const imgEl = e.currentTarget.previousElementSibling as HTMLImageElement;
                      const inputEl = document.querySelector("input[type=file][name=intro-image]") as HTMLInputElement;

                      imgEl.src = "";

                      inputEl.files = null;
                      inputEl.value = "";
                      inputEl.required = true;
                    }}
                  >
                    <IconClose width={12} heigth={12} />
                  </button>
                </div>
                <div className="flex gap-[10px]">
                  <IconInfo fill="green" />
                  <span>이미지 크기는 W = 1080px, 최대 5M</span>
                </div>
              </div>
            </div>
          </div>
          {/* 8. 부작용 info */}
          <div className="rounded-xl border-2">
            {/* 제목 */}
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">부작용 안내</h3>
            </div>
            {/* 입력 양식 컨테이너 */}
            <div className="flex items-end gap-[10px] p-[20px]">
              <textarea
                className="flex-1 resize-none rounded-md border-2 px-[15px] py-[10px]"
                name="description"
                rows={8}
                maxLength={300}
                placeholder={`수술/시술에 따라 전형적으로 발생되는 휴유증 또는 부작용을 알려주세요.\n\n- 예 : 수술/시술 후 일반적으로 발생할 수 있는 출혈,감염,염증 등 부작용들은 개인에 따라 차이가 있을 수 있으므로 주의해 주세요.\n\n- 예 : 일시적으로 색소 침착이나 내출혈,작열감,반흔이 발생할 수 있습니다.`}
                defaultValue={eventDetail.description}
              />
              <span className="text-gray-400">0 / 300</span>
            </div>
          </div>
          {/* 9. submit btn */}
          <div className="mt-[50px] flex justify-end">
            {/* 사용자 버튼 */}
            <button className="h-[40px] min-w-[60px] rounded-3xl bg-green-300 px-[20px] text-white">수정</button>
          </div>
        </form>
      </div>
    </div>
  );
}
