import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { logoutFetch } from "@/api";
import { session, SESSION_LOGIN_KEY } from "@/libs/utils/storage";
import { getExpireTime, setExpireTime, transExpireMin, transExpireSec } from "@/libs/utils/expireTime";

const useHeaderHook = () => {
  const navi = useNavigate();
  const timerRef = useRef<Worker>();
  const [state, setState] = useState<number>(() => getExpireTime());

  const handleSetTimer = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.value === "test") {
      setExpireTime({ sec: 10 });
    }
    if (e.currentTarget.value === "reset") {
      setExpireTime();
    }
    timerRef.current?.postMessage({ type: "reset", time: getExpireTime() });
  };

  const handleLogout = useCallback(async () => {
    const response = await logoutFetch();

    if (response.ok) {
      SESSION_LOGIN_KEY.map(session.delete);
      navi("/login");
    }

    if (!response.ok) {
      alert("로그아웃에 실패했습니다.");
    }
  }, [navi]);

  useEffect(() => {
    const worker = new Worker(new URL("../utils/worker.ts", import.meta.url));
    timerRef.current = worker;

    worker.postMessage({ type: "setting", time: getExpireTime() });
    worker.postMessage({ type: "start" });

    worker.onmessage = (e) => {
      const msg = e.data;

      if (msg.type === "end") {
        handleLogout();
        alert(msg.message);
      }
      if (msg.type === "time") {
        setState(msg.time);
      }
    };
    return () => {
      worker.postMessage({ type: "clear" });
    };
  }, [handleLogout]);

  useEffect(() => {
    document.onvisibilitychange = () =>
      document.visibilityState === "visible" &&
      timerRef.current?.postMessage({ type: "setting", time: getExpireTime() });
  }, []);

  return { handleLogout, handleSetTimer, expire: { min: transExpireMin(state), sec: transExpireSec(state) } };
};

export default useHeaderHook;
