import React from "react";
import { useSearchParams } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";

// import { Pagination } from "@/components/pagination";

export default function InquiryTable() {
  const [searchParams] = useSearchParams();
  // const page = +(searchParams.get("page") ?? "1");

  // const { isSuccess, data } = useQuery({
  //   queryKey: ["inquiry", "hospital", "table", { page }],
  //   queryFn: async () => {
  //     throw new Error("No Data");
  //   },
  //   retry: false
  //   enabled: !!page
  // });

  return (
    <div className="w-max bg-white px-[30px] py-[40px] text-black">
      <form className="mb-[10px] flex justify-end gap-[8px]">
        <select
          className="rounded border p-[10px]"
          name="option"
          defaultValue={searchParams.get("option") ?? "createS"}
        >
          <option value="createS">신청일자</option>
          <option value="writerN">고객명</option>
          <option value="state">상담 진행 상태</option>
          <option value="email">이메일</option>
        </select>
        <input
          className="w-[300px] rounded border p-[10px]"
          type="text"
          name="value"
          defaultValue={searchParams.get("text") ?? ""}
          required
        />
        <button className="rounded-md bg-blue-500 px-[20px] text-white">검색</button>
      </form>
      <table className="mb-[60px] border-t text-center">
        {/* <caption className="mb-[10px] text-left">{`총 게시물 ${isSuccess ? data.paging_info.total_item_count : 0} 개`}</caption> */}
        <colgroup>
          <col className="w-[200px]" data-label="신청일자" />
          <col className="w-[120px]" data-label="고객명" />
          <col className="w-[100px]" data-label="성별" />
          <col className="w-[120px]" data-label="생년월일" />
          <col className="w-[100px]" data-label="국적" />
          <col className="w-[140px]" data-label="이메일" />
          <col className="w-[140px]" data-label="연락처" />
          <col className="w-[240px]" data-label="병력" />
          <col className="w-[240px]" data-label="문의내용" />
          <col className="w-[140px]" data-label="첨부이미지" />
          <col className="w-[140px]" data-label="상담진행 상태" />
          <col className="w-[120px]" data-label="담당자" />
        </colgroup>
        <thead className="border-b-2 border-black text-[14px]">
          <tr className="h-[40px]">
            {[
              "신청일자",
              "고객명",
              "성별",
              "생년월일",
              "국적",
              "이메일",
              "연락처",
              "병력",
              "문의내용",
              "첨부이미지",
              "상담진행 상태",
              "담당자"
            ].map((title) => (
              <td
                key={`hospital-table-${title}`}
                className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
              >
                {title}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* {isSuccess &&
            data.items.map((item: any) => (
              <tr key={item.ms_id} className="h-[40px] border-b">
                <td>{item.create_date}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{null}</td>
                <td>{item.sub_info}</td>
                <td>{item.phone ?? "-"}</td>
                <td>{null}</td>
                <td className="relative">
                  <select
                    defaultValue={item.state}
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                  >
                    <option value="PRE">상담전</option>
                    <option value="m">이메일 연락진행</option>
                    <option value="p">전화 통화진행</option>
                    <option value="i">인터뷰 진행</option>
                    <option value="f">상담완료</option>
                  </select>
                </td>
                <td className="relative">
                  <select
                    defaultValue={item.marketer}
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                  >
                    <option value="m">A</option>
                    <option value="m">B</option>
                    <option value="m">C</option>
                    <option value="마케터">마케터</option>
                  </select>
                </td>
              </tr>
            ))} */}
        </tbody>
      </table>
      {/* {isSuccess && <Pagination totalItem={data.paging_info.total_item_count} viewCount={10} />} */}
    </div>
  );
}
