import React from "react";
import { useQuery } from "@tanstack/react-query";

import { commonFetch } from "@/api";

import SectionContainer from "./SectionContainer";
import useHospitalStore, { ITEM_STATE } from "../hooks/useHospitalStore";

export default function LanguageSection() {
  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  const { data: languageData } = useQuery({
    queryKey: ["common", "language", "allow"],
    queryFn: async () => {
      const response = await commonFetch.get("?classify=LANG&allow=true");

      return response.data.items;
    }
  });
  return (
    <SectionContainer>
      <div className="mb-[10px]">
        <label htmlFor="h-address" className="mr-[30px] text-[14px] font-medium text-purple-500">
          응대가능언어
        </label>
      </div>
      <div className="flex gap-[30px]">
        {languageData &&
          languageData.map((item: any) => (
            <div key={`common-language-key-${item.shared_category_id}`}>
              <input
                type="checkbox"
                id={`common-language-${item.shared_category_id}`}
                className="mr-[5px] align-middle"
                value={item.shared_category_id}
                checked={
                  storeData.shared_category?.find(
                    (cate) =>
                      cate.classify === "LANG" &&
                      cate.shared_category_id === item.shared_category_id &&
                      cate.state !== ITEM_STATE.DELETE
                  )
                    ? true
                    : false
                }
                onChange={(e) => {
                  const id = +e.currentTarget.value;
                  const checked = e.currentTarget.checked;

                  if (!storeData.shared_category) {
                    storeAction.setCommonCategory([
                      {
                        shared_category_id: id,
                        classify: "LANG",
                        state: ITEM_STATE.INSERT
                      }
                    ]);
                    return;
                  }

                  const hasItem = storeData.shared_category.find(
                    (cate) => cate.classify === "LANG" && cate.shared_category_id === id
                  );

                  if (hasItem) {
                    storeAction.setCommonCategory(
                      storeData.shared_category
                        .filter((cate) => {
                          if (cate.classify !== "LANG") return true;

                          if (cate.shared_category_id === id) {
                            const isInsert = !checked && cate.state === ITEM_STATE.INSERT;
                            return isInsert ? false : true;
                          }
                          return true;
                        })
                        .map((cate) => {
                          if (cate.classify !== "LANG") return cate;

                          if (cate.shared_category_id === id) {
                            return {
                              ...cate,
                              state: checked ? ITEM_STATE.STAY : ITEM_STATE.DELETE
                            };
                          }
                          return cate;
                        })
                    );
                    return;
                  }

                  storeAction.setCommonCategory([
                    ...storeData.shared_category,
                    {
                      shared_category_id: id,
                      classify: "LANG",
                      state: ITEM_STATE.INSERT
                    }
                  ]);
                }}
              />
              <label htmlFor={`common-language-${item.shared_category_id}`} className="align-middle">
                {item.text.find((v: any) => v.local === "ko")?.title}
              </label>
            </div>
          ))}
      </div>
    </SectionContainer>
  );
}
