import React from "react";

import SectionContainer from "./SectionContainer";
import { JSON_SCHEDULE } from "../constants";
import useHospitalStore, { ScheduleAllowKey, ScheduleKey } from "../hooks/useHospitalStore";
import { timeFormatting } from "../utils";

export default function ScheduleSection() {
  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    const type = e.currentTarget.type;

    if (type === "time") {
      const key = e.currentTarget.name as ScheduleKey;
      if (!value) {
        storeAction.setSchedule(key, "");
        return;
      }

      const formatting = value.replace(":", "");
      storeAction.setSchedule(key, formatting);
      return;
    }

    if (type === "checkbox") {
      const key = e.currentTarget.name as ScheduleAllowKey;
      const checked = (e.currentTarget as HTMLInputElement).checked;

      storeAction.setScheduleAllow(key, checked);
    }

    const key = e.currentTarget.name;
    storeAction.setData("ko", { [key]: value });
  };

  return (
    <SectionContainer>
      <div className="mb-[10px]">
        <label className="mr-[30px] text-[14px] font-medium text-purple-500">운영시간</label>
        <button
          type="button"
          className="inline-block h-[30px] rounded-md bg-purple-500 px-[20px] text-white"
          onClick={() => alert("??")}
        >
          모든 요일에 복사하기
        </button>
      </div>
      <div className="flex flex-col gap-[5px]">
        {JSON_SCHEDULE.map((item) => (
          <div key={`schedule-${item.key}`}>
            <div className="mr-[20px] inline-block w-[60px]">
              <input
                type="checkbox"
                className="mr-[5px] align-middle"
                name={item.allow}
                checked={storeData[item.allow] ?? false}
                onChange={handleChangeData}
              />
              <label className="align-middle">{item.title}</label>
            </div>
            <div className="inline-flex w-[400px] items-center">
              <input
                type="time"
                className="h-[30px] grow rounded-md border-2 px-[10px]"
                name={item.start}
                value={timeFormatting(storeData[item.start])}
                onChange={handleChangeData}
              />
              <span className="mx-[10px]">-</span>
              <input
                type="time"
                className="h-[30px] grow rounded-md border-2 px-[10px]"
                name={item.end}
                value={timeFormatting(storeData[item.end])}
                onChange={handleChangeData}
              />
            </div>
          </div>
        ))}
        <div className="flex items-center gap-[20px]">
          <div className="w-[60px]">
            <label>기타정보</label>
          </div>
          <div className="relative">
            <input
              type="text"
              className="h-[30px] w-[400px] grow rounded-md border-2 pl-[10px] pr-[50px] placeholder:text-black"
              name="opening_desc"
              value={storeData["ko"]?.opening_desc ?? ""}
              maxLength={50}
              placeholder={"일요일 정기휴무"}
              onChange={handleChangeData}
            />
            <span className="absolute right-[10px] top-1/2 z-10 -translate-y-1/2">{`${storeData["ko"]?.opening_desc?.length ?? 0} / 50`}</span>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
}
