import React from "react";
import { useQuery } from "@tanstack/react-query";

import { IconClose, IconInfo } from "@/components/icons";
import useHospitalStore, { ITEM_STATE } from "../hooks/useHospitalStore";
import { commonFetch } from "@/api";

export default function SubwaySection() {
  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  const { data: subwayData } = useQuery({
    queryKey: ["common", "subway", "allow"],
    queryFn: async () => {
      const response = await commonFetch.get("?classify=SUBWAY&allow=true");

      return response.data.items;
    }
  });

  return (
    <div className="mt-[30px]">
      <div className="mb-[15px] flex items-center">
        <label htmlFor="h-subway" className="mr-[30px] text-[14px] font-medium text-purple-500">
          지하철역
        </label>
        <select
          id="h-subway"
          className="h-[30px] border-2 px-[5px]"
          defaultValue=""
          onChange={(e) => {
            const id = e.currentTarget.value;

            if (!storeData.shared_category) {
              storeAction.setCommonCategory([
                { shared_category_id: +id, classify: "SUBWAY", state: ITEM_STATE.INSERT }
              ]);
              return;
            }

            if (
              storeData.shared_category.filter((item) => item.state !== ITEM_STATE.DELETE && item.classify === "SUBWAY")
                .length > 3
            )
              return;

            const alreadyHasData = storeData.shared_category.find((cate) => cate.shared_category_id === +id);

            if (alreadyHasData) {
              storeAction.setCommonCategory(
                storeData.shared_category.map((cate) => {
                  if (cate.classify !== "SUBWAY") return cate;

                  if (cate.shared_category_id === +id && cate.state === ITEM_STATE.DELETE) {
                    return {
                      ...cate,
                      state: "STAY"
                    };
                  }
                  return cate;
                })
              );
              return;
            }

            storeAction.setCommonCategory([
              ...storeData?.shared_category,
              { shared_category_id: +id, classify: "SUBWAY", state: ITEM_STATE.INSERT }
            ]);
          }}
        >
          <option hidden value="">
            선택해주세요.
          </option>
          {subwayData &&
            subwayData.map((item: any) => (
              <option key={`subway-option-${item.shared_category_id}`} value={item.shared_category_id}>
                {item.text.find((v: any) => v.local === "ko")?.title}
              </option>
            ))}
        </select>
        <div className="ml-[10px] flex items-center gap-[10px]">
          <IconInfo />
          <span>4개까지 선택가능합니다.</span>
        </div>
      </div>
      <div className="flex gap-[10px] px-[10px]">
        {storeData.shared_category &&
          subwayData &&
          storeData.shared_category
            .filter((item: any) => item.state !== ITEM_STATE.DELETE && item.classify === "SUBWAY")
            .map((item: any) => (
              <div
                key={`subway-select-${item.shared_category_id}`}
                className="flex h-[30px] content-center items-center gap-[6px] rounded-md bg-slate-300 px-[15px]"
              >
                <p>
                  {
                    subwayData
                      .find((v: any) => v.shared_category_id === item.shared_category_id)
                      ?.text?.find((v: any) => v.local === "ko")?.title
                  }
                </p>
                <button
                  type="button"
                  className=""
                  value={item.shared_category_id}
                  onClick={(e) => {
                    if (!storeData.shared_category) return;

                    storeAction.setCommonCategory(
                      storeData.shared_category.map((cate) => {
                        if (cate.classify !== "SUBWAY") return cate;

                        if (cate.shared_category_id === +e.currentTarget.value)
                          return {
                            ...cate,
                            state: ITEM_STATE.DELETE
                          };
                        return cate;
                      })
                    );
                  }}
                >
                  <IconClose width={16} heigth={16} />
                </button>
              </div>
            ))}
      </div>
    </div>
  );
}
