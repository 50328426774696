import React from "react";

import Nav from "@/components/nav";
import InquiryBoard from "./components/board";
import InquiryTable from "./components/table";

export default function InquiryHospitalPage() {
  return (
    <div>
      <Nav list={[{ title: "병원 문의관리목록", link: "/inquiry/hospital" }]} />
      <div className="flex min-w-[1080px] flex-col gap-[30px] text-white">
        <InquiryBoard />
        <InquiryTable />
      </div>
    </div>
  );
}
