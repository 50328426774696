import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import LoginPage, { loginLoader } from "./login";

import RootLayout, { rootLoader } from "./layout";

import DashboardPage, { DashboardBoundary, dashboardLoader } from "./dashboard";

import MarketingListPage from "./inquiry/marketing";
import InquiryHospitalPage from "./inquiry/hospital";
import InquiryConsultantPage from "./inquiry/consultant";

import HospitalListPage, { HospitalListLoader } from "./hospital/list/page";
import HospitalInfoPage, { HospitalInfoLoader } from "./hospital/info/page";
import DoctorListPage, { DoctorListLoader } from "./hospital/doctor-list/page";
import DoctorDetailPage, { DoctorDetailLoader } from "./hospital/doctor-detail/page";
import HospitalContactPage, { HospitalContactLoader } from "./hospital/contact/page";

import CommonSubwayPage, { CommonSubwayLoader } from "./common/subway/page";
import CommonMedical from "./common/medical/page";
import CommonServicePage, { CommonServiceLoader } from "./common/service/page";
import CommonLanguagePage, { CommonLanguageLoader } from "./common/language/page";
import CommonCountryPage, { CommonCountryLoader } from "./common/country";

import EventListPage, { EventListLoader } from "./event/list/page";
import EventDetailPage, { EventDetailLoader } from "./event/detail/page";
import EventRegist from "./event/regist/page";
import EventEditPage, { EventEditLoader } from "./event/edit/page";
import EventApprovalList from "./event/approval-list/page";

const router = (queryclient: QueryClient) =>
  createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      loader: rootLoader,
      children: [
        {
          index: true,
          element: <DashboardPage />,
          loader: dashboardLoader,
          errorElement: <DashboardBoundary />
        },
        {
          path: "inquiry",
          children: [
            {
              path: "marketing",
              element: <MarketingListPage />
            },
            {
              path: "hospital",
              element: <InquiryHospitalPage />
            },
            {
              path: "consultant",
              element: <InquiryConsultantPage />
            }
          ]
        },
        {
          path: "hospital",
          children: [
            {
              path: "list",
              element: <HospitalListPage />,
              loader: HospitalListLoader(queryclient)
            },
            {
              path: "regist",
              children: [
                {
                  path: "info/:hospital_id",
                  element: <HospitalInfoPage />,
                  loader: HospitalInfoLoader(queryclient)
                },
                {
                  path: "doctor/:hospital_id",
                  element: <DoctorListPage />,
                  loader: DoctorListLoader(queryclient)
                },
                {
                  path: "doctor/detail/:doctor_id",
                  element: <DoctorDetailPage />,
                  loader: DoctorDetailLoader(queryclient)
                },
                {
                  path: "contact/:hospital_id",
                  element: <HospitalContactPage />,
                  loader: HospitalContactLoader(queryclient)
                }
              ]
            }
          ]
        },
        {
          path: "event",
          children: [
            {
              path: "list",
              element: <EventListPage />,
              loader: EventListLoader(queryclient)
            },
            {
              path: "detail/:event_id",
              element: <EventDetailPage />,
              loader: EventDetailLoader(queryclient)
            },
            {
              path: "regist",
              element: <EventRegist />
            },
            {
              path: "edit/:event_id",
              element: <EventEditPage />,
              loader: EventEditLoader(queryclient)
            },
            {
              path: "approval",
              element: <EventApprovalList />
            }
          ]
        },
        {
          path: "common",
          children: [
            {
              path: "subway",
              element: <CommonSubwayPage />,
              loader: CommonSubwayLoader(queryclient)
            },
            {
              path: "treatment",
              element: <CommonMedical />
            },
            {
              path: "service",
              element: <CommonServicePage />,
              loader: CommonServiceLoader(queryclient)
            },
            {
              path: "language",
              element: <CommonLanguagePage />,
              loader: CommonLanguageLoader(queryclient)
            },
            {
              path: "country",
              element: <CommonCountryPage />,
              loader: CommonCountryLoader(queryclient)
            }
          ]
        },
        {
          path: "*",
          element: <>404 NOT FOUND</>
        }
      ]
    },
    {
      path: "/login",
      element: <LoginPage />,
      loader: loginLoader
    }
  ]);

export default router;
