import React from "react";
import { useSearchParams } from "react-router-dom";

interface PaginationProps {
  totalItem: number;
  viewCount: number;
}

export function Pagination({ totalItem, viewCount }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramsPage = searchParams.get("page") ?? "1";

  const page = {
    now: +paramsPage,
    deps: Math.ceil(+paramsPage / viewCount) - 1,
    last: Math.ceil(totalItem / viewCount),
    lastDeps: Math.ceil(Math.ceil(totalItem / viewCount) / viewCount) - 1,
    value: (index: number) => page.deps * viewCount + index + 1
  };

  const handlePageBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newSearchParams = new URLSearchParams(searchParams);

    switch (e.currentTarget.value) {
      case "first":
        newSearchParams.set("page", "1");
        break;
      case "prev":
        newSearchParams.set("page", `${page.deps * viewCount}`);
        break;
      case "next":
        newSearchParams.set("page", `${(page.deps + 1) * viewCount + 1}`);
        break;
      case "last":
        newSearchParams.set("page", `${Math.ceil(totalItem / viewCount)}`);
        break;
      default:
        newSearchParams.set("page", e.currentTarget.value);
        break;
    }
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex items-center justify-center gap-[4px]">
      <button
        type="button"
        className="aspect-square w-[30px] rounded border bg-gray-200"
        value="first"
        onClick={handlePageBtnClick}
        disabled={page.now === 1}
      >
        {"<<"}
      </button>
      <button
        type="button"
        className="aspect-square w-[30px] rounded border bg-gray-200"
        value="prev"
        onClick={handlePageBtnClick}
        disabled={page.deps === 0}
      >
        {"<"}
      </button>
      {Array.from({ length: viewCount }).map((_, i) => {
        if (page.value(i) > page.last) return null;
        return (
          <button
            key={`page_${i}`}
            className="aspect-square w-[30px] rounded border data-[active='true']:bg-red-100"
            value={page.value(i)}
            onClick={handlePageBtnClick}
            data-active={page.value(i) === page.now}
          >
            {page.value(i)}
          </button>
        );
      })}
      <button
        type="button"
        className="aspect-square w-[30px] rounded border bg-gray-200"
        value="next"
        onClick={handlePageBtnClick}
        disabled={page.deps === page.lastDeps}
      >
        {">"}
      </button>
      <button
        type="button"
        className="aspect-square w-[30px] rounded border bg-gray-200"
        value="last"
        onClick={handlePageBtnClick}
        disabled={page.now === page.last}
      >
        {">>"}
      </button>
    </div>
  );
}
