import { commonCountryFetch } from "@/api";
import { QueryClient } from "@tanstack/react-query";

const alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

export const CommonCountryLoader = (queryClient: QueryClient) => async () =>
  queryClient.ensureQueryData({
    queryKey: ["common", "country"],
    queryFn: async () => {
      const response = await commonCountryFetch.get();
      const items = (response.data.items as {}[])
        .sort((a: any, b: any) => (a.en_name < b.en_name ? -1 : 0))
        .map((item: any) => ({ name: item.en_name, id: item.country_id, type: "item", isOpen: item.is_open_allow }));

      alphabet.forEach((char) => {
        const index = items.findIndex((item) => item.name.startsWith(char));
        items.splice(index, 0, { name: char, id: char, type: "title", isOpen: "Y" });
      });
      return items;
    }
  });
