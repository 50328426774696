export const JSON_LOCALE = [
  {
    key: "ko",
    text: "Korean"
  },
  {
    key: "en",
    text: "English"
  },
  {
    key: "ja",
    text: "Japaness"
  },
  {
    key: "vi",
    text: "Vietnam"
  }
] as const;
