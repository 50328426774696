import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { commonMedicalCategoryFetch, commonMedicalTreatmentFetch } from "@/api";
import { IconAngleDown, IconClose, IconInfo } from "@/components/icons";

export default function EventCategorySection({ medicalIds }: any) {
  const [selectCategory, setSelectCategory] = useState<any>(null);
  const [selectTreat, setSelectTreat] = useState<any>([]);

  const { data: categoryList } = useQuery<any>({
    queryKey: ["common", "medical", "category", medicalIds],
    queryFn: async () => {
      const response = await commonMedicalCategoryFetch.get(`medicalIds=${medicalIds}`);

      return response.data.items;
    },
    enabled: !!medicalIds
  });

  const { data: treatmentList } = useQuery<any>({
    queryKey: ["common", "medical", "treatment", selectCategory],
    queryFn: async () => {
      const response = await commonMedicalTreatmentFetch.get(`medicalCategoryId=${selectCategory}`);

      return response.data.items;
    },
    enabled: !!selectCategory
  });

  useEffect(() => {
    setSelectCategory(null);
    setSelectTreat([]);
  }, [medicalIds]);

  return (
    <div className="p-[20px]">
      <div className="relative mb-[8px] w-fit">
        <h4 className="label-required inline-block text-purple-500">시술분류</h4>
      </div>
      <div className="flex gap-[10px]">
        <div className="relative h-[40px] w-[200px] rounded-md border-2">
          <select
            className="absolute left-0 top-0 z-10 h-full w-full appearance-none bg-transparent pl-[10px] pr-[20px]"
            onClick={(e) => {
              const childCount = e.currentTarget.childElementCount;
              if (childCount > 1) return;
              alert("진료분류 아이템이 없습니다.");
            }}
            onChange={(e) => {
              const categoryId = e.currentTarget.value;

              setSelectCategory(categoryId);
            }}
          >
            <option value="" hidden>
              진료분류
            </option>
            {categoryList &&
              categoryList.map((item: any) => (
                <option
                  key={`medical-${item.medical_id}-category-${item.medical_category_id}`}
                  value={item.medical_category_id}
                >
                  {item.text.find((txt: any) => txt.local === "ko").title}
                </option>
              ))}
          </select>
          <div className="absolute right-[5px] top-1/2 -translate-y-1/2">
            <IconAngleDown fill="black" />
          </div>
        </div>
        <div className="relative mb-[10px] h-[40px] w-[200px] rounded-md border-2">
          <select
            className="absolute left-0 top-0 z-10 h-full w-full appearance-none bg-transparent pl-[10px] pr-[20px] disabled:bg-slate-300"
            disabled={!selectCategory && !treatmentList}
            onClick={(e) => {
              const childCount = e.currentTarget.childElementCount;
              if (childCount > 1) return;
              alert("시술항목 아이템이 없습니다.");
            }}
            onChange={(e) => {
              const treatId = +e.currentTarget.value;

              const alreadyHasItem = selectTreat.findIndex((item: any) => item.treatId === treatId) !== -1;

              if (alreadyHasItem) {
                alert("이미 추가된 시술입니다.");
                return;
              }

              const selectItem = treatmentList.find((item: any) => item.medical_category_item_id === treatId);

              setSelectTreat((prev: any) => [
                ...prev,
                {
                  treatId,
                  title: selectItem.text.find((txt: any) => txt.local === "ko").title
                }
              ]);
            }}
          >
            <option value="" hidden>
              시술항목
            </option>
            {treatmentList &&
              treatmentList.map((item: any) => (
                <option
                  key={`medical-treatment-${item.medical_category_item_id}`}
                  value={item.medical_category_item_id}
                >
                  {item.text.find((txt: any) => txt.local === "ko").title}
                </option>
              ))}
          </select>
          <div className="absolute right-[5px] top-1/2 -translate-y-1/2">
            <IconAngleDown fill="black" />
          </div>
        </div>
      </div>
      {selectTreat.length > 0 ? (
        <div className="mb-[5px] flex h-[50px] gap-[10px] rounded-md border-2 px-[10px] py-[5px]">
          {selectTreat.map((item: any) => (
            <div
              key={`select-treat-item-${item.treatId}`}
              className="h-full content-center rounded-md bg-stone-200 px-[10px]"
            >
              <label className="relative mr-[10px] align-middle">
                <input type="text" className="sr-only" name="treatments" value={item.treatId} readOnly />
                {item.title}
              </label>
              <button
                type="button"
                value={item.treatId}
                onClick={(e) => {
                  const treatId = +e.currentTarget.value;

                  setSelectTreat(selectTreat.filter((v: any) => v.treatId !== treatId));
                }}
              >
                <span className="inline-block align-middle">
                  <IconClose />
                </span>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <input type="text" className="sr-only" required />
      )}
      <div className="flex items-center gap-[5px]">
        <IconInfo fill="red" />
        <p className="text-red-500">이벤트와 관련없는 태그는 통보없이 삭제될 수 있습니다.</p>
      </div>
    </div>
  );
}
