import React from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { Pagination } from "@/components/pagination";
import { marketingFetch, marketingPatchFetch } from "@/api";

export default function InquiryTable({ adminList }: any) {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isSuccess, data } = useQuery({
    queryKey: ["inquiry", "marketing", "table", searchParams.toString()],
    queryFn: async () => {
      const newSearchParams = new URLSearchParams();

      const page = searchParams.get("page");

      if (page) {
        newSearchParams.set("page", `${+page - 1}`);
      }

      const key = searchParams.get("option");
      const value = searchParams.get("value");

      if (key && value) {
        newSearchParams.set(key, value);

        if (key === "createS") newSearchParams.set("createE", value);
      }

      const response = await marketingFetch(newSearchParams.toString());

      if (!response.ok) throw new Error(response.data.error);

      return response.data;
    },
    retry: false
  });

  const stateMutation = useMutation({
    mutationFn: (item: any) => marketingPatchFetch(item.id, item.body),

    onSuccess: () => {
      alert("상태를 변경 했습니다.");
      return queryClient.invalidateQueries({ queryKey: ["inquiry", "marketing", "table", searchParams.toString()] });
    },
    onError: () => {
      alert("상태변경에 실패했습니다.");
      return;
    }
  });

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const option = formData.get("option") as string;
    const value = formData.get("value") as string;
    if (option && value) setSearchParams({ option, value });
  };

  return (
    <div className="w-max bg-white px-[30px] py-[40px] text-black">
      <form className="mb-[10px] flex justify-end gap-[8px]" onSubmit={handleSearchSubmit}>
        <select
          className="rounded border p-[10px]"
          name="option"
          defaultValue={searchParams.get("option") ?? "createS"}
        >
          <option value="createS">신청일자</option>
          <option value="writerN">고객명</option>
          <option value="state">상담 진행 상태</option>
          <option value="email">이메일</option>
        </select>
        <input
          className="w-[300px] rounded border p-[10px]"
          type="text"
          name="value"
          defaultValue={searchParams.get("text") ?? ""}
          required
        />
        <button className="rounded-md bg-blue-500 px-[20px] text-white">검색</button>
      </form>
      <table className="mb-[60px] border-t text-center">
        <caption className="mb-[10px] text-left">{`총 게시물 ${isSuccess ? data.paging_info.total_item_count : 0} 개`}</caption>
        <colgroup>
          <col className="w-[230px]" data-label="신청일자" />
          <col className="w-[150px]" data-label="고객명" />
          <col className="w-[200px]" data-label="이메일" />
          <col className="w-[200px]" data-label="마케팅 채널정보" />
          <col className="w-[200px]" data-label="마케팅 예산" />
          <col className="w-[200px]" data-label="연락처" />
          <col className="w-[300px]" data-label="문의내용" />
          <col className="w-[150px]" data-label="상담진행 상태" />
          <col className="w-[100px]" data-label="담당자" />
        </colgroup>
        <thead className="border-b-2 border-black text-[14px]">
          <tr className="h-[40px]">
            {[
              "신청일자",
              "고객명",
              "이메일",
              "마케팅 채널정보",
              "마케팅 예산",
              "연락처",
              "문의내용",
              "상담진행 상태",
              "담당자"
            ].map((title) => (
              <td
                key={`marketing-t-title-${title}`}
                className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
              >
                {title}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            data.items.map((item: any) => (
              <tr key={item.ms_id} className="h-[40px] border-b">
                <td>{dayjs.tz(dayjs.utc(item.create_date)).format("YYYY-MM-DD HH:mm:ss")}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.channel_name}</td>
                <td>{item.sub_info}</td>
                <td>{item.phone ?? "-"}</td>
                <td>
                  <p className="line-clamp-2 text-left">{item.description}</p>
                </td>
                <td className="relative">
                  <select
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    defaultValue={item.state}
                    onChange={(e) => stateMutation.mutate({ id: item.ms_id, body: { state: e.currentTarget.value } })}
                  >
                    <option value="PRE">상담전</option>
                    <option value="EM">이메일 연락진행</option>
                    <option value="PH">전화 통화진행</option>
                    <option value="IV">인터뷰 진행</option>
                    <option value="CP">상담완료</option>
                  </select>
                </td>
                <td className="relative">
                  <select
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    defaultValue={item.admin_user_id}
                    onChange={(e) =>
                      stateMutation.mutate({ id: item.ms_id, body: { admin_user_id: e.currentTarget.value } })
                    }
                  >
                    <option hidden value={""}>
                      NULL
                    </option>
                    {adminList.map((admin: any) => (
                      <option key={`admin-${admin.mainId}`} value={admin.mainId}>
                        {admin.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isSuccess && <Pagination totalItem={data.paging_info.total_item_count} viewCount={10} />}
    </div>
  );
}
