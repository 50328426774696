const baseUrl = (
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_LIVE_API
) as string;

const endPoint = {
  // 회원목록,
  USER: "/user",
  // 회원가입
  SIGNUP: "/signup",
  // 로그인
  LOGIN: "/login",
  // 로그아웃
  LOGOUT: "/logout",
  // 메인 화면
  SSE: "/subscribe",
  DASHBOARD: "/dashboard",
  // 컨설팅 리스트
  CONSULTANT: "/consultation",
  // 마케팅 리스트
  MARKETING: "/marketing-signups",
  // 병원
  HOSPITAL: "/hospital",
  HOSPITAL_CONTACT: "/hospital/contact",
  HOSPITAL_STATE: "/hospital/state",
  HOSPITAL_MANAGER: "/hospital/manager",
  HOSPITAL_ALLOW: "/hospital/allow",
  // 의사
  DOCTOR: "/doctor",
  DOCTOR_ORDER: "/doctor/order",
  DOCTOR_CONSULT: "/doctor/consult",
  // event
  EVENT: "/event",
  EVENT_APPROVAL: "/event/approval",
  EVENT_APPROVAL_REFUSAL: "/event/approval-refusal",
  // common - 공통
  COMMON_SHARED: "/shared",
  COMMON_SHARED_ALLOW: "/shared-allow",
  // common - 진료과목
  MEDICAL: "/medical",
  MEDICAL_CATEGORY: "/medical-category",
  MEDICAL_ITEM: "/medical-item",
  // common - 국가
  COMMON_COUNTRY: "/country"
} as const;

// baseUrl + endPoint 반복문
const API_URL = Object.fromEntries(
  Object.entries(endPoint).map(([key, value]) => [key, baseUrl.concat(value)])
) as typeof endPoint;

export default API_URL;
