import { consultantFetch, dashboardFetch, marketingFetch } from "@/api";

export const dashboardLoader = async () => {
  const marketing = await dashboardFetch("?type=MARKETER");
  const hospital = await dashboardFetch("?type=HOSPITAL");
  const consultation = await dashboardFetch("?type=CONSULTATION");

  const marketingList = await marketingFetch("");
  const consultantList = await consultantFetch("");

  // if (marketing.ok && hospital.ok && consultation.ok)
  return {
    marketing,
    hospital,
    consultation,
    marketingList,
    consultantList
  };
};
