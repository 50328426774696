import React from "react";
import { Link } from "react-router-dom";

import { IconHouse } from "./icons";

export default function Nav({ list = [] }: any) {
  return (
    <nav className="mb-[20px]">
      <ul className="flex items-center gap-[10px]">
        <li>
          <Link to="/">
            <IconHouse />
          </Link>
        </li>
        {list.map((item: any) => (
          <li
            key={`nav-${item.title}`}
            className="relative pl-[20px] before:absolute before:left-0 before:top-1/2 before:aspect-square before:w-[6px] before:-translate-y-1/2 before:translate-x-1/4 before:rotate-45 before:border-r before:border-t before:border-black"
          >
            <Link to={item.link}>
              <span className="align-middle">{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
