import React, { useEffect, useRef, useState } from "react";
import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { IconClose, IconInfo } from "@/components/icons";
import { commonMedicalCategoryFetch, commonMedicalFetch, doctorDetailFetch } from "@/api";
import useStore, { Data, ImageItem } from "./useStore";

const doctorDetailQuery = (id: string) => ({
  queryKey: ["doctor", "detail", id],
  queryFn: async () => {
    const response = await doctorDetailFetch.get(id);

    const result = response.data;

    delete result.result_code;

    return result;
  }
});

export const DoctorDetailLoader =
  (queryClient: QueryClient) =>
  ({ params: { doctor_id } }: LoaderFunctionArgs) => {
    return queryClient.ensureQueryData(doctorDetailQuery(doctor_id as string));
  };

export default function DoctorDetailPage() {
  const loaderData: any = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();

  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const doctorId = params.doctor_id as string;

  const dialogRef = useRef<HTMLDialogElement>(null);

  const [tab, setTab] = useState(0);

  const { isSuccess, data: detailData } = useQuery<any>({
    ...doctorDetailQuery(doctorId),
    initialData: loaderData
  });

  const { data: medicalData } = useQuery<any>({
    queryKey: ["medical", "open"],
    queryFn: async () => {
      const response = await commonMedicalFetch.get();

      return response.data.items.filter((item: any) => item.is_open_allow);
    }
  });

  const { data: medicalCategoryData } = useQuery<any>({
    queryKey: ["medical", "category", storeData.medical_id, "open"],
    queryFn: async () => {
      const response = await commonMedicalCategoryFetch.get(`?medicalId=${storeData.medical_id}`);

      return response.data.items.filter((item: any) => item.is_open_allow);
    },
    enabled: !!storeData.medical_id
  });

  const mutation = useMutation({
    mutationFn: async (item: FormData) => doctorDetailFetch.put(doctorId, item),
    onSuccess: () => {
      alert("성공");
      navigate(-1);
    },
    onError: () => alert("실패")
  });

  useEffect(() => {
    if (detailData) storeAction.init(detailData);
  }, [detailData, storeAction]);

  return (
    <div>
      <Nav
        list={[
          { title: "병원관리", link: "/hospital/list" },
          { title: "의사정보", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="mr-[15px] inline text-[18px] font-semibold">병원 의사정보</h2>
          <p className="inline">해당 병원의 의사정보를 등록하는 페이지 입니다.</p>
        </div>
        {isSuccess && (
          <section>
            <h3 className="mb-[10px] text-[14px] font-medium">병원 의사정보</h3>
            <div>
              {/* form nav */}
              <div className="flex h-[40px]">
                <button
                  type="button"
                  className="relative mt-[2px] h-full w-[100px] rounded-tl-md rounded-tr-md border-2 bg-slate-300 data-[active=true]:border-b-0 data-[active=true]:bg-white"
                  data-active={tab === 0}
                  onClick={() => setTab(0)}
                >
                  기본정보
                </button>
                <button
                  type="button"
                  className="relative mt-[2px] h-full w-[100px] rounded-tl-md rounded-tr-md border-2 bg-slate-300 data-[active=true]:border-b-0 data-[active=true]:bg-white"
                  data-active={tab === 1}
                  onClick={() => setTab(1)}
                >
                  의사명
                </button>
              </div>
              {/* form */}
              <form
                className="w-[1080px] overflow-hidden rounded-lg rounded-tl-none border-2"
                onSubmit={(e) => {
                  e.preventDefault();

                  const formData = new FormData();

                  if (!storeData.is_terms_agreed) {
                    dialogRef.current?.showModal();
                    return;
                  }

                  for (const k in storeData) {
                    const key = k as keyof Data;
                    // image
                    if (key === "spec_image" || key === "d_license_image" || key === "profile_image") {
                      const data = storeData[key];
                      if (data?.image) {
                        if (storeData[key]?.image_id) {
                          formData.set(`${key}.image_id`, `${storeData[key].image_id}`);
                          formData.set(`${key}.state`, "UPDATE");
                        } else {
                          formData.set(`${key}.state`, "INSERT");
                        }
                        formData.set(`${key}.image`, data.image);
                        continue;
                      }
                      if (data?.image_id) {
                        formData.set(`${key}.image_id`, `${data.image_id}`);
                        formData.set(`${key}.state`, "STAY");
                        continue;
                      }
                    }
                    // text
                    if (key === "text") {
                      const data = storeData[key];

                      data.forEach((v, i) => {
                        formData.set(`${key}[${i}].local`, v.local);
                        formData.set(`${key}[${i}].title`, v.title);
                      });
                      continue;
                    }

                    if (key === "medical_category") {
                      const data = storeData[key];

                      data.forEach((v, i) => {
                        formData.set(`${key}[${i}].m_c_i_id`, `${v.m_c_i_id}`);
                        formData.set(`${key}[${i}].classify`, v.classify);
                        formData.set(`${key}[${i}].state`, v.state);
                      });
                      continue;
                    }

                    formData.set(`${key}`, `${storeData[key]}`);
                  }

                  mutation.mutate(formData);
                }}
              >
                {tab === 0 && (
                  <div>
                    {/* deps-1 의사면허번호 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[20px] flex gap-[10px] border-2 border-black bg-yellow-400 px-[10px] py-[6px]">
                        <IconInfo fill="white" />
                        <p>
                          의사 면허증, 전문의 면허증과 입력하신 번호가 동일해야 승인됩니다. (주민등록번호등과 같은
                          개인정보는 마스킹처리하여 업로드 해주세요)
                        </p>
                      </div>
                      <div className="mb-[5px]">
                        <h4 className="label-required mr-[30px] inline rounded-md text-[14px] font-medium text-purple-500">
                          의사면허번호
                        </h4>
                        <span className="text-[10px] text-red-500">
                          의사 면허 번호는 등록 이후 수정이 불가하므로 신중하게 입력해주세요.
                        </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="mb-[10px] block h-[40px] w-[350px] rounded-md border-2 bg-slate-200 px-[10px]"
                          defaultValue={storeData.d_license}
                          disabled
                        />
                        <div className="flex gap-[5px] pl-[10px]">
                          <IconInfo fill="green" />
                          <p>허위 정보를 입력할 경우 불이익이 있을 수 있습니다.</p>
                        </div>
                      </div>
                    </div>
                    {/* deps-2 분류 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[5px]">
                        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                          의사 분류
                        </h4>
                      </div>
                      <div>
                        <select
                          className="mb-[10px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] disabled:bg-slate-200"
                          value={storeData.type}
                          disabled={!!storeData.type}
                        >
                          <option value="DOCTOR">의사</option>
                          <option value="O_DOCTOR">한의사</option>
                          <option value="DENTIST">치과의사</option>
                        </select>
                        <div className="flex gap-[5px] pl-[10px]">
                          <IconInfo fill="green" />
                          <p>선택 후 수정 불가합니다.</p>
                        </div>
                      </div>
                    </div>
                    {/* deps-3 면허증 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[5px]">
                        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                          의사면허증
                        </h4>
                      </div>
                      <div>
                        <label className="relative mb-[10px] box-content flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
                          <span className="image-plus-box" />
                          이미지 추가
                          <img
                            src={storeData.d_license_image?.image_url}
                            alt="의사면허증"
                            id="license"
                            className="absolute z-10 h-full w-full object-contain"
                            width={300}
                            height={300}
                            hidden={!storeData.d_license_image}
                          />
                          <input
                            type="file"
                            className="sr-only"
                            required={!storeData.d_license_image}
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.currentTarget.files;

                              if (!file || file.length < 1) return;

                              const fileReader = new FileReader();

                              fileReader.onload = (load) => {
                                const url = load.target?.result as string;

                                const data: ImageItem = {
                                  image: file[0],
                                  image_url: url,
                                  state: "INSERT"
                                };
                                if (storeData.d_license_image?.image_id) {
                                  data.image_id = storeData.d_license_image.image_id;
                                  data.state = "UPDATE";
                                }

                                storeAction.setData("d_license_image", data);
                              };

                              fileReader.readAsDataURL(file[0]);
                            }}
                          />
                        </label>
                        <div className="ml-[10px] flex gap-[5px]">
                          <IconInfo />
                          <p>300px * 300px, 최대 2MB</p>
                        </div>
                      </div>
                    </div>
                    {/* deps-4 프로필 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[30px]">
                        <div className="mb-[5px]">
                          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                            의사 프로필 사진
                          </h4>
                        </div>
                        <div>
                          <label className="relative mb-[10px] box-content flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
                            <span className="image-plus-box" />
                            <span>이미지 추가</span>
                            <img
                              src={storeData.profile_image?.image_url}
                              alt="의사프로필"
                              id="profile"
                              className="absolute z-10 h-full w-full object-contain"
                              width={300}
                              height={300}
                              hidden={!storeData.profile_image}
                            />
                            <input
                              type="file"
                              className="sr-only"
                              required={!storeData.profile_image}
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.currentTarget.files;

                                if (!file || file.length < 1) return;

                                const fileReader = new FileReader();

                                fileReader.onload = (load) => {
                                  const url = load.target?.result as string;

                                  const data: ImageItem = {
                                    image: file[0],
                                    image_url: url,
                                    state: "INSERT"
                                  };
                                  if (storeData.profile_image?.image_id) {
                                    data.image_id = storeData.profile_image.image_id;
                                    data.state = "UPDATE";
                                  }

                                  storeAction.setData("profile_image", data);
                                };

                                fileReader.readAsDataURL(file[0]);
                              }}
                            />
                          </label>
                          <div className="ml-[10px] flex gap-[5px]">
                            <IconInfo />
                            300px * 300px, 최대 2MB
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mb-[10px]">
                          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                            성별
                          </h4>
                        </div>
                        <div className="flex items-center">
                          <input
                            type="radio"
                            id="gender-male"
                            className="mr-[5px]"
                            name="gender"
                            defaultChecked={storeData.gender}
                            onChange={(e) => storeAction.setData("gender", e.currentTarget.checked)}
                          />
                          <label htmlFor="gender-male" className="mr-[40px]">
                            남성
                          </label>
                          <input
                            type="radio"
                            id="gender-female"
                            className="mr-[5px]"
                            name="gender"
                            defaultChecked={!storeData.gender}
                            onChange={(e) => storeAction.setData("gender", !e.currentTarget.checked)}
                          />
                          <label htmlFor="gender-female" className="mr-[50px]">
                            여성
                          </label>
                          <input
                            type="checkbox"
                            defaultChecked={storeData.is_head_director}
                            onChange={(e) => storeAction.setData("is_head_director", e.currentTarget.checked)}
                          />
                          <label className="ml-[5px]">대표원장 여부</label>
                          <div className="ml-[20px] flex gap-[5px]">
                            <IconInfo fill="purple" />
                            <p>대표원장일 경우 의사명단중 맨 위에 게시됩니다.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* deps-5 전문의 자격 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[5px]">
                        <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">전문의 자격</h4>
                      </div>
                      <div>
                        <select
                          className="mb-[10px] block h-[40px] w-[350px] rounded-md border-2 px-[10px]"
                          name="medical_id"
                          value={storeData.medical_id ?? ""}
                          onChange={(e) => {
                            storeAction.setData("medical_id", e.currentTarget.value);

                            if (storeData.medical_category.length > 0) {
                              storeAction.setData(
                                "medical_category",
                                storeData.medical_category.map((item) => ({ ...item, state: "DELETE" }))
                              );
                            }
                          }}
                        >
                          <option value="" hidden>
                            선택해 주세요.
                          </option>
                          {medicalData &&
                            medicalData.map((item: any) => (
                              <option value={item.medical_id} key={`doctor-medical-${item.medical_id}`}>
                                {item.text.find((txt: any) => txt.local === "ko")?.title ?? "No Text"}
                              </option>
                            ))}
                        </select>
                        <div className="ml-[10px] flex gap-[5px]">
                          <IconInfo fill="green" />
                          <p>
                            실제 전문의 자격이 있는 전공만 선택해야 합니다. 허위인 경우 경고없이 삭제될 수 있으며, 해당
                            전공이 없는 경우 전문의 아님을 선택해 주세요.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* deps-6 전문의 자격번호 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[5px]">
                        <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">전문의 자격번호</h4>
                        <span className="text-[10px] text-red-500">
                          전문의 자격번호는 등록 이후 수정이 불가하므로 신중하게 입력해주세요.
                        </span>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
                          name="spec_license_no"
                          placeholder="숫자만 입력해 주세요."
                          value={storeData.spec_license_no ?? ""}
                          pattern="^[0-9]+$"
                          onChange={(e) => {
                            const serial = e.currentTarget.value;

                            storeAction.setData("spec_license_no", serial);
                          }}
                        />
                        <div className="ml-[10px] flex gap-[5px]">
                          <IconInfo fill="green" />
                          <p>허위 정보를 입력할 경우 불이익이 있을 수 있습니다.</p>
                        </div>
                      </div>
                    </div>
                    {/* deps-7  전문의 자격증 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[5px]">
                        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                          전문의 자격증
                        </h4>
                      </div>
                      <div>
                        <label className="relative mb-[10px] box-content flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
                          <span className="image-plus-box" />
                          이미지 추가
                          <img
                            src={storeData.spec_image?.image_url}
                            alt="전문의 자격증"
                            id="spec"
                            className="absolute h-full w-full object-contain"
                            width={300}
                            height={300}
                            hidden={!storeData.spec_image}
                          />
                          <input
                            type="file"
                            className="sr-only"
                            name="spec_image"
                            required={!storeData.spec_image}
                            accept="image/*"
                            onChange={(e) => {
                              const file = e.currentTarget.files;

                              if (!file || file.length < 1) return;

                              const fileReader = new FileReader();

                              fileReader.onload = (load) => {
                                const url = load.target?.result as string;

                                const data: ImageItem = {
                                  image: file[0],
                                  image_url: url,
                                  state: "INSERT"
                                };
                                if (storeData.spec_image?.image_id) {
                                  data.image_id = storeData.spec_image.image_id;
                                  data.state = "UPDATE";
                                }

                                storeAction.setData("spec_image", data);
                              };

                              fileReader.readAsDataURL(file[0]);
                            }}
                          />
                        </label>
                        <div className="ml-[10px] flex gap-[5px]">
                          <IconInfo />
                          <p>300px * 300px, 최대 2MB</p>
                        </div>
                      </div>
                    </div>
                    {/* deps-8 진료 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[10px]">
                        <label className="label-required mr-[30px] text-[14px] font-medium text-purple-500">
                          진료과목
                        </label>
                        <select
                          className="h-[30px] rounded-md border-2 px-[10px]"
                          defaultValue=""
                          onChange={(e) => {
                            const id = +e.target.value;

                            const hasCategory = storeData.medical_category.find((category) => category.m_c_i_id === id);

                            if (hasCategory) {
                              storeAction.setData(
                                "medical_category",
                                storeData.medical_category.map((category) => {
                                  if (category?.m_c_i_id === id) {
                                    if (category.state === "DELETE") {
                                      return {
                                        ...category,
                                        state: "STAY"
                                      };
                                    }
                                  }
                                  return category;
                                })
                              );
                              return;
                            }
                            storeAction.setData("medical_category", [
                              ...storeData.medical_category,
                              {
                                m_c_i_id: id,
                                classify: "CATEGORY",
                                state: "INSERT"
                              }
                            ]);
                          }}
                        >
                          <option value="" hidden>
                            선택해 주세요
                          </option>
                          {medicalCategoryData &&
                            medicalCategoryData.map((item: any) => (
                              <option
                                value={item.medical_category_id}
                                key={`medical-category-${item.medical_category_id}`}
                              >
                                {item.text.find((txt: any) => txt.local === "ko")?.title ?? "No text"}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="flex gap-[10px] rounded-md border-2 p-[10px]">
                        {medicalCategoryData &&
                          storeData.medical_category
                            .filter((category) => category.state !== "DELETE")
                            .map((category) => (
                              <button
                                type="button"
                                key={`medical-category-select-${category.m_c_i_id}`}
                                className="h-[30px] rounded-md bg-slate-300 px-[15px] after:ml-[10px] after:[content:'X']"
                                value={category.m_c_i_id}
                                onClick={(e) => {
                                  const id = +e.currentTarget.value;
                                  const hasCategory = storeData.medical_category.find(
                                    (category) => category.m_c_i_id === id
                                  )?.state;

                                  if (hasCategory) {
                                    storeAction.setData(
                                      "medical_category",
                                      storeData.medical_category.map((category) => {
                                        if (category?.m_c_i_id === id) {
                                          return {
                                            ...category,
                                            state: category.state === "DELETE" ? "STAY" : "DELETE"
                                          };
                                        }
                                        return category;
                                      })
                                    );
                                    return;
                                  }
                                  storeAction.setData(
                                    "medical_category",
                                    storeData.medical_category.filter((category) => category?.m_c_i_id !== id)
                                  );
                                }}
                              >
                                {
                                  medicalCategoryData
                                    .find((item: any) => `${item.medical_category_id}` === `${category.m_c_i_id}`)
                                    ?.text?.find((txt: any) => txt.local === "ko")?.title
                                }
                              </button>
                            ))}
                      </div>
                    </div>
                    {/* deps-9 상담 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[30px]">
                        <div className="mb-[10px]">
                          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                            의사 상담가능 여부
                          </h4>
                        </div>
                        <div>
                          <label className="custom-check-button mr-[10px]">
                            <input
                              type="checkbox"
                              className="sr-only"
                              checked={storeData.is_consultation}
                              onChange={(e) => storeAction.setData("is_consultation", e.currentTarget.checked)}
                            />
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="mb-[10px]">
                          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                            진료가능 상태
                          </h4>
                        </div>
                        <div>
                          <select
                            className="h-[30px] w-[150px] rounded-md border-2 px-[10px]"
                            name="state"
                            required
                            value={storeData.state}
                            onChange={(e) => storeAction.setData("state", e.currentTarget.value)}
                          >
                            <option value="TREATMENT">진료중</option>
                            <option value="LEAVE">휴진중</option>
                            <option value="RETIREMENT">퇴직</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* deps-10 약관 */}
                    <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                      <div className="mb-[10px]">
                        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                          약관동의
                        </h4>
                      </div>
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            className="mr-[5px] align-middle"
                            checked={storeData.is_terms_agreed}
                            onChange={(e) => storeAction.setData("is_terms_agreed", e.currentTarget.checked)}
                          />
                          <span className="align-middle">개인정보 수집 이용에 동의합니다.</span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {tab === 1 && (
                  <div className="flex flex-col gap-[20px] py-[30px] pl-[20px] pr-[100px]">
                    <div>
                      <div className="mb-[5px]">
                        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                          한국어
                        </h4>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black disabled:bg-slate-200"
                          defaultValue={storeData.text.find((txt: any) => txt.local === "ko")?.title}
                          disabled
                        />
                        <div className="flex gap-[5px] pl-[10px]">
                          <IconInfo fill="green" />
                          <p>국문 의사명 수정을 요청시 미인 고객센터에 문의 해 주시길 바랍니다.</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mb-[5px]">
                        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                          영어
                        </h4>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
                          placeholder="영문명을 입력해 주세요"
                          defaultValue={storeData.text.find((txt: any) => txt.local === "en")?.title}
                          required
                          onChange={(e) => {
                            const text = e.currentTarget.value;

                            const hasData = storeData.text.find((txt) => txt.local === "en");

                            if (hasData) {
                              storeAction.setData(
                                "text",
                                storeData.text.map((txt) => (txt.local === "en" ? { ...txt, title: text } : txt))
                              );
                              return;
                            }
                            storeAction.setData("text", [...storeData.text, { local: "en", title: text }]);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mb-[5px]">
                        <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">일어</h4>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
                          placeholder="일본어 이름을 입력해 주세요"
                          value={storeData.text.find((txt: any) => txt.local === "ja")?.title}
                          onChange={(e) => {
                            const text = e.currentTarget.value;

                            const hasData = storeData.text.find((txt) => txt.local === "ja");

                            if (hasData) {
                              storeAction.setData(
                                "text",
                                storeData.text.map((txt) => (txt.local === "ja" ? { ...txt, title: text } : txt))
                              );
                              return;
                            }
                            storeAction.setData("text", [...storeData.text, { local: "ja", title: text }]);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mb-[5px]">
                        <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">베트남어</h4>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
                          placeholder="베트남어 이름을 입력해 주세요"
                          defaultValue={storeData.text.find((txt: any) => txt.local === "vi")?.title}
                          onChange={(e) => {
                            const text = e.currentTarget.value;

                            const hasData = storeData.text.find((txt) => txt.local === "vi");

                            if (hasData) {
                              storeAction.setData(
                                "text",
                                storeData.text.map((txt) => (txt.local === "vi" ? { ...txt, title: text } : txt))
                              );
                              return;
                            }
                            storeAction.setData("text", [...storeData.text, { local: "vi", title: text }]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* button */}
                <div className="flex justify-end gap-[10px] p-[20px]">
                  <button type="reset" className="h-[40px] w-[70px] rounded-3xl bg-stone-400 text-white">
                    취소
                  </button>
                  <button className="h-[40px] w-[150px] rounded-3xl bg-red-400 text-white">저장하기</button>
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
      <dialog ref={dialogRef} className="rounded-md">
        <form method="dialog" className="relative flex w-[220px] flex-col justify-between bg-white p-[12px]">
          <button className="absolute right-[10px] top-[10px]">
            <IconClose />
          </button>
          <div className="m-auto mb-[8px] flex flex-col items-center gap-[10px] px-[20px]">
            <IconInfo width={50} heigth={50} fill="blue" />
            <p>약관 동의를 하셔야 저장을 완료 할 수 있습니다.</p>
          </div>
          <div className="text-right">
            <button className="border-2 px-[20px] py-[5px]">확인</button>
          </div>
        </form>
      </dialog>
    </div>
  );
}
