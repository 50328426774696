import React, { useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { IconClose, IconPen } from "@/components/icons";
import { commonFetch } from "@/api";

interface MoveItemType {
  id: number;
  is_open_allow: boolean;
  title?: string;
}

const subwayQuery = {
  queryKey: ["common", "subway"],
  queryFn: async () => {
    const response = await commonFetch.get("?classify=SUBWAY");

    const result = response.data.items.map((item: any) => {
      const title = item.text
        .reduce((acc: any, cur: any) => {
          if (cur.local === "ko") acc[0] = cur.title || "null";
          if (cur.local === "en") acc[1] = cur.title || "null";
          if (cur.local === "vi") acc[2] = cur.title || "null";
          if (cur.local === "ja") acc[3] = cur.title || "null";
          return acc;
        }, [])
        .join(" / ");
      return {
        id: item.shared_category_id,
        is_open_allow: item?.is_open_allow,
        title
      };
    });

    return result;
  }
};

export const CommonSubwayLoader = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(subwayQuery);
};

export default function CommonSubwayPage() {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();

  const useListRef = useRef<HTMLUListElement>(null);
  const disuseListRef = useRef<HTMLUListElement>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);

  const [modifyItem, setModifyItem] = useState<any>(null);
  const [moveItems, setMoveItems] = useState<MoveItemType[]>([]);
  const [checkItems, setcheckItems] = useState<MoveItemType[]>([]);

  const { data, isSuccess } = useQuery<any>({ ...subwayQuery, initialData: loaderData });

  const addMutation = useMutation({
    mutationFn: (item: any) => commonFetch.post(item),
    onSuccess: () => {
      const dialogEl = dialogRef.current;
      alert("역이 추가되었습니다.");

      if (dialogEl && dialogEl.open) dialogEl.close();

      return queryClient.invalidateQueries({ queryKey: subwayQuery.queryKey });
    },
    onError: () => {
      alert("역 추가를 실패했습니다..");
      return;
    }
  });
  const putMutation = useMutation({
    mutationFn: ({ id, item }: any) => commonFetch.put(id, item),
    onSuccess: () => {
      const dialogEl = dialogRef.current;
      alert("역이 수정되었습니다.");

      if (dialogEl && dialogEl.open) dialogEl.close();
      setMoveItems([]);
      setcheckItems([]);
      setModifyItem(null);
      return queryClient.invalidateQueries({ queryKey: subwayQuery.queryKey });
    },
    onError: () => {
      alert("역 수정을 실패했습니다..");
      return;
    }
  });

  const patchMutation = useMutation({
    mutationFn: (item: any) => commonFetch.patch(item),
    onSuccess: (_, __) => {
      alert(`역을 수정했습니다.`);
      setMoveItems([]);
      setcheckItems([]);
      return queryClient.invalidateQueries({ queryKey: subwayQuery.queryKey });
    },
    onError: (_, __) => {
      alert(`역 추가를 실패했습니다..`);
      return;
    }
  });

  const handleRegistSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const itemList = ["ko", "en", "vi", "ja"].map((locale) => ({
      local: locale,
      title: formData.get(locale) ?? ""
    }));

    if (modifyItem) {
      putMutation.mutate({
        id: modifyItem.id,
        item: { text: itemList }
      });
      return;
    }
    addMutation.mutate({
      text: itemList,
      classify: "SUBWAY"
    });
  };

  const handleItemChecked = (item: any) => {
    const hasDiffAllow = checkItems.some((checkItem) => checkItem.is_open_allow !== item.is_open_allow);

    if (hasDiffAllow) {
      if (item.is_open_allow) {
        const disuseUList = disuseListRef.current as HTMLUListElement;
        const disuseEl = disuseUList.querySelectorAll<HTMLInputElement>("input[name='disuseItem']");
        disuseEl.forEach((el) => (el.checked = false));
      } else {
        const useUList = useListRef.current as HTMLUListElement;
        const useEl = useUList.querySelectorAll<HTMLInputElement>("input[name='useItem']");
        useEl.forEach((el) => (el.checked = false));
      }
      setcheckItems([{ id: item.id, is_open_allow: item.is_open_allow, title: item.title }]);
      return;
    }

    setcheckItems((prev) => [...prev, { id: item.id, is_open_allow: item.is_open_allow, title: item.title }]);
  };

  const handleMoveItems = (e: React.MouseEvent<HTMLButtonElement>) => {
    const isUse = e.currentTarget.value === "use";

    if (checkItems.length < 1) {
      alert("선택한 역이 없습니다.");
      return;
    }

    const wrongChange = checkItems.some((item) => item.is_open_allow === isUse);

    if (wrongChange) {
      alert(`선택한 역이 이미 ${isUse ? "노출" : "미노출"} 상태입니다.`);
      return;
    }

    const newItems = checkItems.map((item) => ({ ...item, is_open_allow: isUse }));
    setMoveItems((prev) => [...prev, ...newItems]);
    setcheckItems([]);
  };

  const handleCancel = () => {
    const disuseUList = disuseListRef.current as HTMLUListElement;
    const disuseEl = disuseUList.querySelectorAll<HTMLInputElement>("input[name='disuseItem']");
    disuseEl.forEach((el) => (el.checked = false));

    const useUList = useListRef.current as HTMLUListElement;
    const useEl = useUList.querySelectorAll<HTMLInputElement>("input[name='useItem']");
    useEl.forEach((el) => (el.checked = false));

    setMoveItems([]);
    setcheckItems([]);
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.value;
    const title = e.currentTarget.dataset.title as string;

    const [ko, en, vi, ja] = title.split(" / ");

    setModifyItem({
      id: id,
      ko: ko === "null" ? "" : ko,
      en: en === "null" ? "" : en,
      vi: vi === "null" ? "" : vi,
      ja: ja === "null" ? "" : ja
    });
    const dialogEl = dialogRef.current;
    if (dialogEl && !dialogEl.open) dialogEl.showModal();
  };

  const handleSaveSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    patchMutation.mutate({
      items: moveItems.map((item) => ({ is_open_allow: item.is_open_allow, shared_category_id: item.id }))
    });
  };

  return (
    <div>
      <Nav
        list={[
          { title: "공통관리", link: null },
          { title: "지하철역명 관리", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="mr-[15px] inline text-[18px] font-semibold">지하철역명 관리</h2>
          <p className="inline">병원 근처 부가 정보를 위한 관리입니다.</p>
        </div>
        <section>
          <div>
            {/* nav */}
            <div className="relative h-[40px] w-[100px] place-content-center rounded-tl-md rounded-tr-md border-2 border-b-0 text-center after:absolute after:-bottom-[2px] after:left-0 after:h-[2px] after:w-full after:bg-white">
              기본정보
            </div>
            {/* form */}
            <form className="w-[800px] overflow-hidden rounded-lg rounded-tl-none border-2" onSubmit={handleSaveSubmit}>
              <div className="py-[30px] pl-[20px] pr-[40px]">
                <div className="mb-[30px]">
                  <h3 className="text-[14px] font-medium text-purple-500">지하철 역명 추가</h3>
                </div>
                <div className="flex h-[600px] justify-between gap-[30px]">
                  <div className="flex-1 rounded-md border-2">
                    <div className="flex h-[40px] w-full gap-2 border-b-2 p-1">
                      <input
                        className="flex-1 px-[10px] placeholder:text-black"
                        type="text"
                        placeholder="지하철역명을 입력해주세요"
                      />
                      <button
                        type="button"
                        className="h-full w-[50px] rounded-md bg-red-400 text-white"
                        onClick={() => {
                          const dialogEl = dialogRef.current;
                          if (dialogEl && !dialogEl.open) dialogEl.showModal();
                        }}
                      >
                        추가
                      </button>
                    </div>
                    <ul className="px-[12px] py-[20px]" ref={disuseListRef}>
                      {isSuccess &&
                        data
                          .filter((item: any) => !item.is_open_allow && !moveItems.some((v) => v.id === item.id))
                          .map((item: any) => (
                            <li
                              key={`subway-disuse-${item.id}`}
                              className="flex justify-between has-[input:checked]:bg-red-300"
                            >
                              <label className="flex-1" onChange={handleItemChecked.bind(null, item)}>
                                <input className="hidden" type="checkbox" name="disuseItem" />
                                {item.title}
                              </label>
                              <button
                                type="button"
                                value={item.id}
                                data-title={item.title}
                                onClick={handleEditButtonClick}
                              >
                                <IconPen />
                              </button>
                            </li>
                          ))}
                      {moveItems
                        .filter((item) => !item.is_open_allow)
                        .map((item) => (
                          <li key={`subway-disuse-move-${item.id}`} className="flex justify-between bg-yellow-300">
                            <label className="flex-1">
                              <input className="hidden" type="checkbox" name="disuseItem" disabled />
                              {item.title}
                            </label>
                            <button
                              type="button"
                              value={item.id}
                              data-title={item.title}
                              onClick={handleEditButtonClick}
                            >
                              <IconPen />
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="w-[30px] self-center">
                    <button
                      className="mb-2 h-[40px] w-full rounded-md border-2"
                      type="button"
                      value="disuse"
                      onClick={handleMoveItems}
                    >
                      {"<"}
                    </button>
                    <button
                      className="h-[40px] w-full rounded-md border-2"
                      type="button"
                      value="use"
                      onClick={handleMoveItems}
                    >
                      {">"}
                    </button>
                  </div>
                  <div className="flex-1 rounded-md border-2 py-[15px]">
                    <ul className="h-full overflow-auto px-[12px] pl-[10px]" ref={useListRef}>
                      {isSuccess &&
                        data
                          .filter((item: any) => item.is_open_allow && !moveItems.some((v) => v.id === item.id))
                          .map((item: any) => (
                            <li
                              key={`subway-use-${item.id}`}
                              className="flex justify-between has-[input:checked]:bg-blue-300"
                            >
                              <label className="flex-1" onChange={handleItemChecked.bind(null, item)}>
                                <input className="hidden" type="checkbox" name="useItem" />
                                {item.title}
                              </label>
                              <button
                                type="button"
                                value={item.id}
                                data-title={item.title}
                                onClick={handleEditButtonClick}
                              >
                                <IconPen />
                              </button>
                            </li>
                          ))}
                      {moveItems
                        .filter((item) => item.is_open_allow)
                        .map((item) => (
                          <li key={`subway-disuse-move-${item.id}`} className="flex justify-between bg-yellow-300">
                            <label className="flex-1">
                              <input className="hidden" type="checkbox" name="useItem" disabled />
                              {item.title}
                            </label>
                            <button
                              type="button"
                              value={item.id}
                              data-title={item.title}
                              onClick={handleEditButtonClick}
                            >
                              <IconPen />
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* button */}
              <div className="flex justify-center gap-[10px] p-[20px]">
                <button
                  className="h-[40px] w-[70px] rounded-3xl bg-stone-400 text-white"
                  type="button"
                  onClick={handleCancel}
                >
                  취소
                </button>
                <button className="h-[40px] w-[150px] rounded-3xl bg-red-400 text-white">저장하기</button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <dialog
        ref={dialogRef}
        className="rounded-xl"
        onClose={(e) => {
          const formEl = e.currentTarget.querySelector("form");
          if (formEl) formEl.reset();
        }}
        onReset={(e) => e.currentTarget.close()}
      >
        <form method="dialog" className="w-[500px] bg-white" onSubmit={handleRegistSubmit}>
          {/* popup - head */}
          <div className="flex h-[50px] items-center justify-between bg-slate-700 pl-[20px] pr-[10px]">
            <h3 className="text-[20px] text-white">{modifyItem ? "지하철역명 수정하기" : "지하철역명 등록하기"}</h3>
            <button type="reset">
              <IconClose fill="white" width={30} heigth={30} />
            </button>
          </div>
          {/* popup - body */}
          <div className="flex flex-col gap-[20px] p-[20px]">
            <div>
              <label htmlFor="locale-ko" className="label-required">
                한국어
              </label>
              <input
                type="text"
                id="locale-ko"
                name="ko"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder="서울역"
                defaultValue={modifyItem?.ko}
                required
              />
            </div>
            <div>
              <label htmlFor="locale-en" className="label-required">
                영어
              </label>
              <input
                type="text"
                id="locale-en"
                name="en"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder="Seoul Station"
                defaultValue={modifyItem?.en}
                required
              />
            </div>
            <div>
              <label htmlFor="locale-vi">베트남어</label>
              <input
                type="text"
                id="locale-vi"
                name="vi"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder=""
                defaultValue={modifyItem?.vi}
              />
            </div>
            <div>
              <label htmlFor="locale-ja">일본어</label>
              <input
                type="text"
                id="locale-ja"
                name="ja"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder=""
                defaultValue={modifyItem?.ja}
              />
            </div>
          </div>
          {/* popup - footer */}
          <div className="mb-[20px] px-[20px] text-right">
            <button type="reset" className="mr-[10px] rounded-full bg-stone-500 px-[30px] py-[10px] text-white">
              취소
            </button>
            <button className="mr-[10px] rounded-full bg-red-500 px-[30px] py-[10px] text-white">등록</button>
          </div>
        </form>
      </dialog>
    </div>
  );
}
