import React from "react";
import { LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { IconInfo } from "@/components/icons";
import { hospitalContactFetch } from "@/api";

const hospitalContactQuery = (id: string) => ({
  queryKey: ["hospital", id, "contact"],
  queryFn: async () => {
    const response = await hospitalContactFetch.get(id);

    return { ...response.data, main_phone: response.data.main_phone?.split("-") };
  }
});

export const HospitalContactLoader =
  (queryClient: QueryClient) =>
  ({ params }: LoaderFunctionArgs) => {
    return queryClient.ensureQueryData(hospitalContactQuery(params.hospital_id as string));
  };

export default function HospitalContactPage() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const { data } = useQuery<any>({ ...hospitalContactQuery(hospitalId), initialData: loaderData });

  const mutation = useMutation({
    mutationFn: async (item: any) => hospitalContactFetch.post(hospitalId, item),
    onSuccess: () => {
      alert("성공");

      navigate(-1);
    },
    onError: () => alert("fail")
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const item = {
      main_phone: formData.getAll("main_phone").join("-"),
      inquiry_phone: formData.get("inquiry_phone"),
      contact_phone: formData.get("contact_phone"),
      contact_name: formData.get("contact_name"),
      mkt_email: formData.get("mkt_email")
    };

    mutation.mutate(item);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    navigate(-1);
  };

  return (
    <div>
      <Nav
        list={[
          { title: "병원관리", link: "/hospital/list" },
          { title: "연락처 정보", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="mr-[15px] inline text-[18px] font-semibold">병원 연락처정보</h2>
          <p className="inline">해당 병원의 의사정보를 등록하는 페이지 입니다.</p>
        </div>
        <section>
          <div>
            {/* form */}
            <form
              className="w-[1080px] overflow-hidden rounded-lg rounded-tl-none border-2"
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              {/* deps-1 전화번호 */}
              <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                <div className="mb-[20px] flex h-[40px] items-center gap-[10px] rounded-md border-2 border-black bg-yellow-400 px-[10px]">
                  <IconInfo fill="white" />
                  <span>ARS 전화번호는 연락처 정보에 기입할 수 없습니다.</span>
                </div>
                <div className="mb-[5px]">
                  <h4 className="label-required mr-[30px] inline rounded-md text-[14px] font-medium text-purple-500">
                    대표 전화번호
                  </h4>
                  <span className="text-[10px] text-red-500">병원의 대표 전화번호를 입력해 주세요.</span>
                </div>
                <div>
                  <input
                    type="tel"
                    className="h-[40px] w-[60px] border-2 text-center"
                    name="main_phone"
                    defaultValue={data?.main_phone?.[0]}
                    maxLength={4}
                    required
                  />
                  <span className="mx-[5px]">-</span>
                  <input
                    type="tel"
                    className="h-[40px] w-[80px] border-2 text-center"
                    name="main_phone"
                    defaultValue={data?.main_phone?.[1]}
                    maxLength={4}
                    required
                  />
                  <span className="mx-[5px]">-</span>
                  <input
                    type="tel"
                    className="h-[40px] w-[80px] border-2 text-center"
                    defaultValue={data?.main_phone?.[2]}
                    name="main_phone"
                    maxLength={4}
                    required
                  />
                </div>
              </div>
              {/* deps-2 전화 담당 */}
              <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                <div className="mb-[5px]">
                  <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">전화문의 담당 전화</h4>
                </div>
                <div>
                  <input
                    type="tel"
                    className="mb-[5px] block h-[40px] w-[300px] border-2 px-[10px]"
                    name="inquiry_phone"
                    defaultValue={data?.inquiry_phone}
                  />
                  <div className="ml-[15px] flex gap-[10px]">
                    <IconInfo fill="#a9e086" />
                    <div>
                      <p>숫자만 입력해주세요.</p>
                      <p>050,060,070,1522,1577,1688,1899등의 ARS 연동 번호는 등록하셔도 연결이 되지 않습니다.</p>
                      <p>앱을 이용한 고객과 전화상담을 원하지 않는경우, 해당 정보는 공란으로 비워 두시면 됩니다.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* deps-3 상담 번호 */}
              <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                <div className="mb-[5px]">
                  <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-purple-500">
                    병원 상담자 연락처
                  </h4>
                </div>
                <div>
                  <input
                    type="tel"
                    className="mb-[5px] block h-[40px] w-[300px] border-2 px-[10px] placeholder:text-black"
                    name="contact_phone"
                    placeholder="입력하세요."
                    defaultValue={data?.contact_phone}
                  />
                  <div className="ml-[15px] flex gap-[10px]">
                    <IconInfo fill="#a9e086" />
                    <div>
                      <p>고객이 앱상에서 당담을 신청했을 때, 알림을 받으실 병원 담당자의 전화번호를 입력해 주세요.</p>
                      <p>해당 알림은 카카오톡 / 문자 메시지로 전달됩니다.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* deps-4 이메일 */}
              <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
                <div className="mb-[20px]">
                  <div className="mb-[5px]">
                    <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">병원 담당자명</h4>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="mb-[5px] block h-[40px] w-[300px] border-2 px-[10px] placeholder:text-black"
                      name="contact_name"
                      placeholder="성함을 입력해 주세요."
                      defaultValue={data?.contact_name}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-[5px]">
                    <h4 className="mr-[30px] inline text-[14px] font-medium text-purple-500">병원측 담당자 이메일</h4>
                  </div>
                  <div>
                    <input
                      type="email"
                      className="mb-[5px] block h-[40px] w-[300px] border-2 px-[10px] placeholder:text-black"
                      name="mkt_email"
                      placeholder="이메일 정보를 입력해 주세요."
                      defaultValue={data?.mkt_email}
                    />
                  </div>
                </div>
              </div>
              {/* button */}
              <div className="flex justify-end gap-[10px] p-[20px]">
                <button type="reset" className="h-[40px] w-[70px] rounded-3xl bg-stone-400 text-white">
                  취소
                </button>
                <button className="h-[40px] w-[150px] rounded-3xl bg-red-400 text-white">저장하기</button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}
