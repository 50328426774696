import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { loginFetch } from "@/api";
import { session } from "@/libs/utils/storage";
import { setExpireTime } from "@/libs/utils/expireTime";

const useLoginHook = () => {
  const navigate = useNavigate();

  const errorRef = useRef<HTMLDialogElement>(null);

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const user_id = formData.get("user_id");
    const password = formData.get("password");

    try {
      const response = await loginFetch({ user_id, password });

      if (response.ok) {
        session.set("loginTime", dayjs.utc(response.data.result_code.timestamp));
        session.set("permission", response.data.permission_category);
        setExpireTime({ date: response.data.result_code.timestamp });

        navigate("/");
        return;
      }
    } catch (e) {
      if (errorRef.current) {
        errorRef.current.showModal();
        return;
      }
      alert("로그인에 실패했습니다.");
      return;
    }
  };

  const handleCopyButton = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const adminEmail = e.currentTarget.dataset.email ?? "admin@test.com";
    navigator.clipboard.writeText(adminEmail);
    alert("success copy");
  };

  return { errorRef, handleLoginSubmit, handleCopyButton };
};

export default useLoginHook;
