import React from "react";
import useLoginHook from "./loign.hook";
import { IconClose, IconPaperClip } from "@/components/icons";

export default function LoginPage() {
  const { errorRef, handleLoginSubmit, handleCopyButton } = useLoginHook();

  return (
    <div className="grid h-screen w-screen grid-cols-[45%1fr] grid-rows-3 bg-[#fffbf7]">
      <img
        src={require("@/assets/images/login/bg-login.jpg")}
        alt="miin login page background"
        className="col-start-1 col-end-2 row-start-1 row-end-4 h-full w-full"
      />
      <img
        src={require("@/assets/images/login/logo-login.png")}
        alt="miin logo"
        className="relative bottom-[60px] right-[92px] col-start-1 col-end-2 row-start-2 row-end-3 justify-self-end"
      />
      <div className="col-start-2 col-end-3 row-start-2">
        <div className="relative left-[92px] w-fit">
          <h1 className="mb-[14px] w-fit text-[50px] font-bold">미인 관리자페이지</h1>
          <p className="mb-[65px] w-fit text-[20px]">아래의 입력란에 로그인정보를 입력해주세요.</p>
          <form className="flex w-[450px] flex-col items-end gap-[20px]" onSubmit={handleLoginSubmit}>
            <label className="flex w-full items-center justify-between text-base">
              아이디
              <input
                type="text"
                className="bd h-[44px] w-[380px] rounded border px-3 text-sm"
                name="user_id"
                required
              />
            </label>
            <label className="flex w-full items-center justify-between text-base">
              비밀번호
              <input
                type="password"
                className="h-[44px] w-[380px] rounded border px-3 text-sm"
                name="password"
                required
              />
            </label>
            <input
              type="submit"
              value="확인"
              className="h-[44px] w-[380px] cursor-pointer rounded bg-[#6b207e] text-white"
            />
          </form>
        </div>
      </div>
      <dialog ref={errorRef} className="rounded-xl">
        <form method="dialog" className="relative flex h-[200px] w-[400px] flex-col justify-between p-[20px_30px]">
          <button className="absolute right-[10px] top-[10px] z-[1]">
            <IconClose width={30} heigth={30} />
          </button>
          <div className="my-[5px]">
            <div className="relative mb-[10px] pl-[30px]">
              <h2 className="align-middle text-[20px] font-bold">로그인에 실패하였습니다</h2>
              <span className="absolute left-0 top-1/2 inline-block aspect-square w-[15px] -translate-y-1/2 rounded-full border-4 border-purple-500" />
            </div>
            <div className="pl-[30px]">
              <p className="text-[14px] font-medium">관리자에 로그인 정보를 다시 확인해주세요</p>
            </div>
          </div>
          <div className="flex gap-[10px]">
            <button
              className="flex items-center justify-center gap-[10px] rounded-md border bg-purple-500 px-[20px] text-white"
              data-email="text@test.com"
              onClick={handleCopyButton}
            >
              <span className="flex aspect-square w-[20px] items-center justify-center rounded-full bg-white">
                <IconPaperClip />
              </span>
              <span className="align-middle">관리자 이메일 복사하기</span>
            </button>
            <button className="rounded-md border border-black p-[10px_15px] font-medium">로그인 재시도하기</button>
          </div>
        </form>
      </dialog>
    </div>
  );
}
