import dayjs from "dayjs";
import { session } from "./storage";

interface ExTimeParams {
  date?: dayjs.ConfigType;
  sec?: number;
}

export const setExpireTime = (params?: ExTimeParams) => {
  session.set("expireTime", dayjs.utc(params?.date).add(params?.sec ?? 1801, "second"));
};

export const getExpireTime = () => {
  const value = dayjs.utc(session.get("expireTime")).diff(dayjs.utc(), "second");
  if (value > 1800) {
    setExpireTime();
    return 1800;
  }
  return value;
};

export const transExpireMin = (t: number) =>
  Math.floor(t / 60)
    .toString()
    .padStart(2, "0");

export const transExpireSec = (t: number) => (t % 60).toString().padStart(2, "0");
