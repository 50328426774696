import React from "react";

import Nav from "@/components/nav";
import InquiryBoard from "./components/board";
import InquiryTable from "./components/table";
import { useQuery } from "@tanstack/react-query";
import { userFetch } from "@/api";

export default function InquiryConsultantPage() {
  const { isSuccess, data } = useQuery({
    queryKey: ["admin", "user", "list", "ADMIN,SADMIN"],
    queryFn: async () => {
      const response = await userFetch("?types=SUPER,ADMIN,SADMIN");

      return response.data.items;
    },
    retry: false
  });
  return (
    <div>
      <Nav list={[{ title: "상담 문의관리목록", link: "/inquiry/consultant" }]} />
      <div className="flex min-w-[1080px] flex-col gap-[30px] text-white">
        <InquiryBoard />
        <InquiryTable adminList={isSuccess ? data : []} />
      </div>
    </div>
  );
}
