import React from "react";
import { Outlet } from "react-router-dom";

import { RootAside, RootHeader } from "./components";

export default function RootLayout() {
  return (
    <div className="grid min-h-screen min-w-max grid-cols-[216px_minmax(0,1fr)] grid-rows-[50px_minmax(0,1fr)]">
      <RootHeader />
      <RootAside />
      <main className="col-start-2 col-end-3 bg-[#dee1e6] px-[40px] py-[30px]">
        <Outlet />
      </main>
    </div>
  );
}
