import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Pagination } from "@/components/pagination";
import { IconClose } from "@/components/icons";

import { consultantDetailFetch, consultantFetch, consultantPatchFetch } from "@/api";
import { convertTime } from "@/utils/date";

export default function InquiryTable({ adminList }: any) {
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [detailId, setDetailId] = useState<string | null>(null);

  const imageRef = useRef<HTMLDialogElement>(null);

  const { isSuccess, data } = useQuery({
    queryKey: ["inquiry", "consultant", "table", searchParams.toString()],
    queryFn: async () => {
      const newSearchParams = new URLSearchParams();

      const page = searchParams.get("page");

      if (page) {
        newSearchParams.set("page", `${+page - 1}`);
      }

      const key = searchParams.get("option");
      const value = searchParams.get("value");

      if (key && value) {
        newSearchParams.set(key, value);

        if (key === "createS") newSearchParams.set("createE", value);
      }

      const response = await consultantFetch(newSearchParams.toString());

      if (!response.ok) throw new Error(response.data.error);

      return response.data;
    },
    retry: false
  });

  const { data: detail } = useQuery({
    queryKey: ["inquiry", "consultant", "detail", detailId],
    queryFn: async () => {
      if (!detailId) return null;

      const response = await consultantDetailFetch(detailId);

      return response.data;
    },
    enabled: !!detailId
  });

  const stateMutation = useMutation({
    mutationFn: (item: any) => consultantPatchFetch(item.id, item.body),

    onSuccess: () => {
      alert("상태를 변경 했습니다.");
      return queryClient.invalidateQueries({ queryKey: ["inquiry", "marketing", "table", searchParams.toString()] });
    },
    onError: () => {
      alert("상태변경에 실패했습니다.");
      return;
    }
  });

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const option = formData.get("option") as string;
    const value = formData.get("value") as string;
    if (option && value) setSearchParams({ option, value });
  };

  return (
    <div className="w-max bg-white px-[30px] py-[40px] text-black">
      <form className="mb-[10px] flex justify-end gap-[8px]" onSubmit={handleSearchSubmit}>
        <select
          className="rounded border p-[10px]"
          name="option"
          defaultValue={searchParams.get("option") ?? "createS"}
        >
          <option value="createS">신청일자</option>
          <option value="writerN">고객명</option>
          <option value="state">상담 진행 상태</option>
          <option value="email">이메일</option>
        </select>
        <input
          className="w-[300px] rounded border p-[10px]"
          type="text"
          name="value"
          defaultValue={searchParams.get("text") ?? ""}
          required
        />
        <button className="rounded-md bg-blue-500 px-[20px] text-white">검색</button>
      </form>
      <table className="mb-[60px] border-t text-center">
        <caption className="mb-[10px] text-left">{`총 게시물 ${isSuccess ? data.paging_info.total_item_count : 0} 개`}</caption>
        <colgroup>
          <col className="w-[160px]" data-label="신청일자" />
          <col className="w-[100px]" data-label="고객명" />
          <col className="w-[100px]" data-label="성별" />
          <col className="w-[140px]" data-label="생년월일" />
          <col className="w-[100px]" data-label="국적" />
          <col className="w-[140px]" data-label="이메일" />
          <col className="w-[140px]" data-label="연락처" />
          <col className="w-[200px]" data-label="병력" />
          <col className="w-[200px]" data-label="문의내용" />
          <col className="w-[180px]" data-label="첨부이미지" />
          <col className="w-[140px]" data-label="상담진행 상태" />
          <col className="w-[140px]" data-label="담당자" />
        </colgroup>
        <thead className="border-b-2 border-black text-[14px]">
          <tr className="h-[40px]">
            {[
              "신청일자",
              "고객명",
              "성별",
              "생년월일",
              "국적",
              "이메일",
              "연락처",
              "병력",
              "문의내용",
              "첨부이미지",
              "상담진행 상태",
              "담당자"
            ].map((title) => (
              <td
                key={`consultant-${title}`}
                className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
              >
                {title}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            data.items.map((item: any) => (
              <tr key={item.ms_id} className="h-[40px] border-b">
                <td>{convertTime(item.create_date)}</td>
                <td>{item.writer_name ?? "-"}</td>
                <td>{item.gender ?? "-"}</td>
                <td>{item.birthday}</td>
                <td>{item.country}</td>
                <td>{item.email}</td>
                <td>{item.phone ?? "-"}</td>
                <td>
                  <p className="line-clamp-2 text-left">{item.medical_history ?? "-"}</p>
                </td>
                <td>
                  <p className="line-clamp-2 text-left">{item.description}</p>
                </td>
                <td className="relative">
                  <button
                    type="button"
                    className="absolute left-1/2 top-1/2 h-[80%] w-[70%] -translate-x-1/2 -translate-y-1/2 rounded-md bg-purple-600 align-middle text-white disabled:bg-gray-200 disabled:text-black"
                    disabled={!item.is_image}
                    onClick={() => {
                      imageRef.current?.showModal();
                      setDetailId(item.consultation_id);
                    }}
                  >
                    {item.is_image ? "이미지 보기" : "첨부없음"}
                  </button>
                </td>
                <td className="relative">
                  <select
                    defaultValue={item.state}
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    onChange={(e) =>
                      stateMutation.mutate({ id: item.consultation_id, body: { state: e.currentTarget.value } })
                    }
                  >
                    <option hidden value="">
                      NULL
                    </option>
                    <option value="PRE">상담전</option>
                    <option value="EM">이메일 연락진행</option>
                    <option value="PH">전화 통화진행</option>
                    <option value="IV">인터뷰 진행</option>
                    <option value="CP">상담완료</option>
                  </select>
                </td>
                <td className="relative">
                  <select
                    defaultValue={item.admin_user_id}
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    onChange={(e) =>
                      stateMutation.mutate({ id: item.consultation_id, body: { admin_user_id: e.currentTarget.value } })
                    }
                  >
                    <option hidden value="">
                      NULL
                    </option>
                    {adminList.map((admin: any) => (
                      <option key={`admin-${admin.mainId}`} value={admin.mainId}>
                        {admin.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isSuccess && <Pagination totalItem={data.paging_info.total_item_count} viewCount={10} />}
      <dialog ref={imageRef} className="rounded-xl" onClose={() => setDetailId(null)}>
        {detail && (
          <form method="dialog" className="relative flex h-[600px] w-[1080px] flex-col justify-between p-[40px_50px]">
            <button className="absolute right-[30px] top-[20px] z-[1]">
              <IconClose />
            </button>
            <div>
              <p>{`신청일자 : ${convertTime(data?.items?.find((item: any) => detail?.consultation_info?.consultation_id === item.consultation_id)?.create_date) ?? null}`}</p>
              <p>{`고객명 : ${detail?.consultation_info?.name}`}</p>
            </div>
            <div className="mx-auto grid grid-cols-[repeat(3,300px)] grid-rows-[400px] items-center justify-items-center gap-[20px]">
              {[...detail?.image_info, ...Array(3)].slice(0, 3).map((image: any) => (
                <div className="leading-[0]">
                  <span className="inline-block h-[400px] w-[300px] border after:inline-block after:h-full after:w-full after:bg-[linear-gradient(to_left_top,transparent_calc(50%-1px),rgb(229,231,235)_50%,transparent_calc(50%+1px)),linear-gradient(to_right_top,transparent_calc(50%-1px),rgb(229,231,235)_50%,transparent_calc(50%+1px))] has-[+img[src]]:hidden" />
                  <img src={image?.image_url} alt="" />
                </div>
              ))}
            </div>
          </form>
        )}
      </dialog>
    </div>
  );
}
