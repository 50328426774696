import React, { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { IconClose, IconFile, IconPen } from "@/components/icons";

import { commonMedicalFetch } from "@/api";
import { useMedicalStore } from "../hooks";
import { CommonMedicalKey } from "../utils/queryKey";

export default function MedicalForm() {
  const store = useMedicalStore();
  const queryClient = useQueryClient();

  const listRef = useRef<HTMLUListElement>(null);
  const detailRef = useRef<HTMLDialogElement>(null);
  const modifyRef = useRef<HTMLDialogElement>(null);

  const [pickItem, setPickItem] = useState<any>(null);

  const { isSuccess, data } = useQuery({
    queryKey: CommonMedicalKey.medical,
    queryFn: async () => {
      const response = await commonMedicalFetch.get();
      return response.data.items;
    },
    retry: false
  });

  const addMutation = useMutation({
    mutationFn: async (item: any) => commonMedicalFetch.post(item),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: CommonMedicalKey.medical });
      modifyRef.current?.close();
    },
    onError: () => {
      alert("실패");
    }
  });

  const putMutation = useMutation({
    mutationFn: async ({ id, item }: any) => commonMedicalFetch.put(id, item),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: CommonMedicalKey.medical });
      modifyRef.current?.close();
    },
    onError: () => {
      alert("실패");
    }
  });

  const patchMutation = useMutation({
    mutationFn: async (item: any) => commonMedicalFetch.patch(item),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: CommonMedicalKey.medical });
    },
    onError: () => {
      alert("실패");
    }
  });

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const search = formData.get("search")?.toString();

    const listEl = listRef.current;

    if (listEl) {
      const searchEl = listEl.querySelector("button[data-search='true']") as HTMLButtonElement;
      if (searchEl) searchEl.dataset.search = "false";
    }

    const xpath = `//*[@id='medical-list']//button[contains(text(),'${search}')]`;
    const matchingElement = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.ANY_UNORDERED_NODE_TYPE,
      null
    ).singleNodeValue;

    if (!matchingElement) return alert("검색 결과가 존재하지 않습니다.");

    const matchEl = matchingElement as HTMLButtonElement;

    matchEl.dataset.search = "true";
    matchEl.scrollIntoView({ behavior: "smooth" });
  };

  const handleModifyClick = (item: any, type?: any) => {
    setPickItem(item);
    if (type === "detail") {
      detailRef.current?.showModal();
      return;
    }
    modifyRef.current?.showModal();
  };

  const handleModifySubmit = (type: "edit" | "create", e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const arr = [];
    const formData = new FormData(e.currentTarget);

    const ko = formData.get("ko");
    const en = formData.get("en");
    const vi = formData.get("vi");
    const ja = formData.get("ja");

    arr.push({ local: "ko", title: ko });
    arr.push({ local: "en", title: en });
    arr.push({ local: "vi", title: vi });
    arr.push({ local: "ja", title: ja });

    if (type === "create") {
      addMutation.mutate({ text: arr });
      return;
    }
    putMutation.mutate({ id: pickItem.medical_id, item: { text: arr } });
  };

  const handleChangeAllow = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isOpen = e.currentTarget.checked ? true : false;
    const id = e.currentTarget.value;

    patchMutation.mutate({ items: [{ medical_id: id, is_open_allow: isOpen }] });
  };

  const handleDeps = (e: React.MouseEvent<HTMLButtonElement>) => {
    const medicalId = e.currentTarget.value;
    if (medicalId === store.medical) {
      store.setMedical("");
      return;
    }
    store.setMedical(medicalId);
  };

  return (
    <div className="w-[400px]">
      <div className="mb-[20px]">
        <h3 className="text-[14px] font-medium text-purple-500">진료과 1단계</h3>
      </div>
      <div id="medical-deps" className="rounded-md border-2">
        <form className="flex h-[40px] w-full gap-2 border-b-2 p-1" onSubmit={handleSearch}>
          <input
            className="flex-1 px-[10px] placeholder:text-black"
            type="text"
            name="search"
            placeholder="진료과목명을 입력해 주세요"
            required
          />
          <button className="h-full w-[50px] rounded-md bg-blue-400 text-white">검색</button>
          <button
            type="button"
            className="h-full w-[50px] rounded-md bg-red-400 text-white"
            onClick={handleModifyClick.bind(null, null)}
          >
            추가
          </button>
        </form>
        <ul id="medical-list" ref={listRef} className="h-[550px] overflow-y-auto scroll-smooth py-[10px]">
          {isSuccess &&
            data.map((item: any) => (
              <li
                key={`medical-${item.medical_id}`}
                className="px-[10px] py-[6px] hover:bg-purple-300 has-[button[data-search='true']]:bg-red-200 data-[active='true']:!bg-purple-500"
                data-active={store.medical === `${item.medical_id}`}
              >
                <div className="flex gap-[10px]">
                  <input
                    type="checkbox"
                    value={item.medical_id}
                    defaultChecked={item.is_open_allow}
                    onChange={handleChangeAllow}
                  />

                  <button type="button" className="scroll-mt-[6px]" value={item.medical_id} onClick={handleDeps}>
                    {item.text
                      .reduce((acc: any, cur: any) => {
                        if (cur.local === "ko") acc[0] = cur;
                        if (cur.local === "en") acc[1] = cur;
                        if (cur.local === "vi") acc[2] = cur;
                        if (cur.local === "ja") acc[3] = cur;
                        return acc;
                      }, [])
                      .map((text: any) => text.title)
                      .filter(Boolean)
                      .join(" / ")}
                  </button>
                  <div className="ml-auto flex items-center gap-[10px]">
                    <button type="button" onClick={handleModifyClick.bind(null, item, "detail")}>
                      <IconFile />
                    </button>
                    <button type="button" onClick={handleModifyClick.bind(null, item)}>
                      <IconPen />
                    </button>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {/* popup - detail */}
      <dialog ref={detailRef} className="rounded-xl border border-stone-500">
        <form method="dialog" className="w-[500px] bg-white p-[20px] pt-[10px]">
          {/* popup - head */}
          <div className="mb-[10px] flex h-[40px] justify-between">
            <h2 className="self-end text-[16px]">등록된 진료항목명 자세히 보기</h2>
            <div>
              <button
                type="button"
                className="mr-[10px] rounded-md bg-red-500 px-[12px] py-[5px] text-[18px] text-white"
                onClick={() => {
                  detailRef.current?.close();
                  modifyRef.current?.showModal();
                }}
              >
                수정
              </button>
              <button className="self-start">
                <IconClose width={30} heigth={30} />
              </button>
            </div>
          </div>
          {/* popup - content */}
          <div className="rounded-md bg-gray-200 px-[20px] py-[10px] pl-[40px]">
            <table className="[&_td]:text-center [&_th]:w-[160px] [&_th]:text-left [&_tr]:h-[30px]">
              <tbody>
                <tr>
                  <th scope="row">국문</th>
                  <td>{pickItem && pickItem.text.find((v: any) => v.local === "ko")?.title}</td>
                </tr>
                <tr>
                  <th scope="row">영문</th>
                  <td>{pickItem && pickItem.text.find((v: any) => v.local === "en")?.title}</td>
                </tr>
                <tr>
                  <th scope="row">베트남어</th>
                  <td>{pickItem && pickItem.text.find((v: any) => v.local === "vi")?.title}</td>
                </tr>
                <tr>
                  <th scope="row">일본어</th>
                  <td>{pickItem && pickItem.text.find((v: any) => v.local === "ja")?.title}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </dialog>
      {/* popup - edit + create*/}
      <dialog
        ref={modifyRef}
        className="rounded-xl"
        onClose={(e) => {
          const el = e.currentTarget;
          const formEl = el.querySelector("form");
          if (formEl) formEl.reset();
        }}
        onReset={(e) => e.currentTarget.close()}
      >
        <form
          method="dialog"
          className="w-[500px] bg-white"
          onSubmit={handleModifySubmit.bind(null, pickItem ? "edit" : "create")}
        >
          {/* popup - head */}
          <div className="flex h-[50px] items-center justify-between bg-slate-700 pl-[20px] pr-[10px]">
            <h2 className="text-[20px] text-white">{pickItem ? "진료과 수정" : "진료과 등록"}</h2>
            <button type="reset">
              <IconClose fill="white" width={30} heigth={30} />
            </button>
          </div>
          {/* popup - content */}
          <div className="flex flex-col gap-[20px] p-[20px]">
            <div>
              <label htmlFor="locale-ko" className="label-required">
                한국어
              </label>
              <input
                type="text"
                id="locale-ko"
                name="ko"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder="국문 진료과목멱을 입력해주세요"
                defaultValue={pickItem && pickItem.text.find((v: any) => v.local === "ko")?.title}
                required
              />
            </div>
            <div>
              <label htmlFor="locale-en" className="label-required">
                영어
              </label>
              <input
                type="text"
                id="locale-en"
                name="en"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder="영어 진료과목멱을 입력해주세요"
                defaultValue={pickItem && pickItem.text.find((v: any) => v.local === "en")?.title}
                required
              />
            </div>
            <div>
              <label htmlFor="locale-vi">베트남어</label>
              <input
                type="text"
                id="locale-vi"
                name="vi"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder="베트남어 진료과목멱을 입력해주세요"
                defaultValue={pickItem && pickItem.text.find((v: any) => v.local === "vi")?.title}
              />
            </div>
            <div>
              <label htmlFor="locale-ja">일본어</label>
              <input
                type="text"
                id="locale-ja"
                name="ja"
                className="mt-[5px] block w-full rounded-md border px-[20px] py-[10px]"
                placeholder="일본어 진료과목멱을 입력해주세요"
                defaultValue={pickItem && pickItem.text.find((v: any) => v.local === "ja")?.title}
              />
            </div>
          </div>
          {/* popup - footer */}
          <div className="mb-[20px] px-[20px] text-right">
            <button type="reset" className="mr-[10px] rounded-full bg-stone-500 px-[30px] py-[10px] text-white">
              취소
            </button>
            <button className="mr-[10px] rounded-full bg-red-500 px-[30px] py-[10px] text-white">
              {pickItem ? "수정하기" : "등록하기"}
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
}
