import React, { useEffect } from "react";

import Nav from "@/components/nav";

import { CategoryForm, MedicalForm, TreatmentForm } from "./components";
import { useMedicalStore } from "./hooks";

export default function CommonMedical() {
  const { reset } = useMedicalStore();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <div>
      <Nav
        list={[
          { title: "공통관리", link: null },
          { title: "진료과목 관리", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="mr-[15px] inline text-[18px] font-semibold">진료과목 관리</h2>
          <p className="inline">진료과목명을 관리합니다.</p>
        </div>

        <div className="flex gap-[30px] rounded-lg rounded-tl-none border-2 px-[20px] py-[30px]">
          <MedicalForm />
          <CategoryForm />
          <TreatmentForm />
        </div>
      </div>
    </div>
  );
}
