import React from "react";
import useHospitalStore from "../hooks/useHospitalStore";

export default function SimpleAddressSection({ locale }: { locale: "ko" | "en" | "vi" | "ja" }) {
  const storeData = useHospitalStore((state) => state.data);
  const storeAction = useHospitalStore((state) => state.action);

  return (
    <div>
      <label className="label-required mr-[30px] text-[14px] font-medium text-purple-500">주소</label>
      <button type="button" className="inline-block h-[30px] rounded-md bg-purple-500 px-[20px] text-white">
        주소검색
      </button>
      <input
        type="text"
        name="address-etc"
        className="mt-[10px] h-[40px] w-full rounded-md border-2 px-[20px]"
        value={storeData[locale]?.sub_address ?? ""}
        onChange={(e) => storeAction.setData(locale, { sub_address: e.target.value })}
      />
    </div>
  );
}
