import { create } from "zustand";

export interface ImageItem {
  image_id?: number;
  image_url?: string;
  image?: Blob;
  state?: "DELETE" | "UPDATE" | "STAY" | "INSERT";
}

interface TextItem {
  local: "en" | "ko" | "ja" | "vi";
  title: string;
  description?: string | null;
}
interface CategoryItem {
  m_c_i_id: number;
  classify: "CATEGORY";
  state: "DELETE" | "UPDATE" | "STAY" | "INSERT" | string;
}

export interface Data {
  d_license: string;
  spec_license_no: string | null;
  gender: boolean;
  type: "DOCTOR" | "O_DOCTOR" | "DENTIST";
  is_terms_agreed: boolean;
  is_head_director: boolean;
  is_consultation: boolean;
  profile_image: ImageItem | null;
  d_license_image: ImageItem | null;
  spec_image: ImageItem | null;
  medical_id: number | null;
  medical_category: CategoryItem[];
  state: "TREATMENT" | "RETIREMENT" | "LEAVE";
  text: TextItem[];
}

interface State {
  data: Data;
}
interface Action {
  action: {
    init: (data: Data) => void;
    reset: () => void;
    setData: (key: keyof Data, value: Data[keyof Data]) => void;
  };
}

const initialData: Data = {
  d_license: "",
  spec_license_no: "",
  gender: false,
  type: "DOCTOR",
  is_terms_agreed: false,
  is_head_director: false,
  is_consultation: false,
  profile_image: null,
  d_license_image: null,
  spec_image: null,
  medical_id: null,
  medical_category: [],
  state: "LEAVE",
  text: []
};

const useStore = create<State & Action>((set) => ({
  data: initialData,
  action: {
    init: (data) => set(() => ({ data })),
    reset: () => set(() => ({ data: initialData })),
    setData: (key, value) => set((state) => ({ data: { ...state.data, [key]: value } }))
  }
}));

export default useStore;
