export const SESSION_LOGIN_KEY = ["loginTime", "expireTime", "permission"] as const;
export const SESSION_ERROR_KEY = "error" as const;

type SessionLogin = (typeof SESSION_LOGIN_KEY)[number];
type SessionError = typeof SESSION_ERROR_KEY;

type SessionStorageKey = SessionLogin | SessionError;

export const session = {
  get: (k: SessionStorageKey) => sessionStorage.getItem(k),
  set: (k: SessionStorageKey, v: any) => sessionStorage.setItem(k, v.toString()),
  delete: (k: SessionStorageKey) => sessionStorage.removeItem(k),
  clear: () => sessionStorage.clear()
};
