import React from "react";
import { useLoaderData } from "react-router-dom";
import Board from "./components/board";

import Nav from "@/components/nav";

const imageConfig = {
  marketing: {
    top: "bg-[url('@/assets/images/dashboard/marketing-top.png')]",
    bot: "bg-[url('@/assets/images/dashboard/marketing-bot.png')]"
  },
  hospital: {
    top: "bg-[url('@/assets/images/dashboard/hospital-top.png')]",
    bot: "bg-[url('@/assets/images/dashboard/hospital-bot.png')]"
  },
  consultant: {
    top: "bg-[url('@/assets/images/dashboard/consultant-top.png')]",
    bot: "bg-[url('@/assets/images/dashboard/consultant-bot.png')]"
  }
};

export default function DashboardPage() {
  const { marketing, hospital, consultation, marketingList, consultantList } = useLoaderData() as any;

  return (
    <div>
      <Nav list={[{ title: "대시보드", link: "/" }]} />
      <div className="grid h-full w-full grid-cols-[repeat(3,520px)] gap-x-[20px] text-white">
        <Board
          type={"m"}
          title={"마케팅문의"}
          image={imageConfig.marketing}
          data={marketing.data}
          list={marketingList.data.items}
        />
        <Board
          type={"h"}
          title={"병원입점문의"}
          image={imageConfig.hospital}
          data={hospital.data}
          list={Array.from({ length: 1 })}
        />
        <Board
          type={"c"}
          title={"상담문의"}
          image={imageConfig.consultant}
          data={consultation.data}
          list={consultantList.data.items}
        />
      </div>
    </div>
  );
}
